<template>

    <div>

        <div class="detail-title">{{ page_title }}</div>

        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="-1"
            hide-default-footer
            :search="search"
            :custom-filter="customSearch"
            :loading="loading"
            sort-by="publicinformations"
            :sort-desc="true"
            dense
            item-class="data-item"
            must-sort
            :header-props="{sortIcon: 'mdi-arrow-up-thick'}"
        >

            <!-- referencia -->
            <template v-slot:item.code="{ item }">
                <span :title="item.code_ext">{{ item.code }}</span>
            </template>

            <!-- tramitación -->
            <template v-slot:item.processing_status="{ item }">
                <Location
                    :img_size="'15px'"
                    :value="item.viewer_url"
                    :value_text="item.processing_status"
                    :icon_color="caption_bgcolor"
                    :label="item.processing_status_label">
                </Location>
            </template>

            <!-- documento navegable -->
            <template #item.browsable_document_path="{ item }">
                <BrowsableDocument
                    :data_folder_path="dataFolderPath"
                    :value="item.browsable_document_path"
                    :pending="item.browsable_document_pending"
                    :icon_color="caption_bgcolor">
                </BrowsableDocument>
            </template>

            <!-- descarga sin firma -->
            <template #item.unsigned_documentation="{ item }">
                <UnsignedDownload
                    :data_url="dataUrl"
                    :data_folder_path="dataFolderPath"
                    :item="item"
                    :_caption_bgcolor="caption_bgcolor"
                    :caption_bgcolor="'#d9d9d9'">
                </UnsignedDownload>
            </template>

            <!-- descarga con firma -->
            <template #item.signed_documentation="{ item }">
                <SignedDownload
                    :data_url="dataUrl"
                    :data_folder_path="dataFolderPath"
                    :item="item"
                    :caption_bgcolor="'#d9d9d9'">
                </SignedDownload>
            </template>

            <!-- expediente administrativo -->
            <template #item.administrative_exp_documentation="{ item }">
                <AdministrativeExpedientDownload
                    :data_url="dataUrl"
                    :data_folder_path="data_folder_path"
                    :item="item"
                    :caption_bgcolor="'#d9d9d9'">
                </AdministrativeExpedientDownload>
            </template>

            <!-- publicación en boletín -->
            <template #item.publicinformations="{ value }">
                <PublicInformations
                    :data_folder_path="dataFolderPath"
                    :value="value">
                </PublicInformations>
            </template>

        </v-data-table>

    </div>

</template>

<script>
    import { eventBus } from '../main'
    import {
        customSearch,
        getHistoryData, apiCall, getItems,
        publicInformationsSort
    } from '../modules/common'
    import BrowsableDocument from './BaseBrowsableDocument'
    import UnsignedDownload from './BaseUnsignedDownload'
    import SignedDownload from './BaseSignedDownload'
    import AdministrativeExpedientDownload from './BaseAdministrativeExpedient'
    import PublicInformations from './BasePublicInformations'
    import Location from './BaseLocation'

    export default {
        components: {
            BrowsableDocument,
            UnsignedDownload,
            SignedDownload,
            AdministrativeExpedientDownload,
            PublicInformations,
            Location
        },
        name: 'PNENPRNDetailHistory',
        props: {
            page_title: String,
            detail_id: Number,
            data_url: String,
            data_folder_path: String,
            caption_bgcolor: String
        },
        data: () => ({
            options: {},
            items: [],
            total_items: 0,
            loading: true,
            search: '',
            headers: [
                {
                    text: 'Referencia',
                    value: 'code'
                },
                {
                    text: 'Tramitación',
                    value: 'processing_status',
                    sortable: true
                },
                {
                    text: 'Documento navegable optimizado',
                    value: 'browsable_document_path',
                    sortable: false,
                    align: 'center',
                    width: 215
                },
                {
                    text: 'Descarga sin firma',
                    value: 'unsigned_documentation',
                    sortable: false,
                    align: 'center',
                    width: 130
                },
                {
                    text: 'Descarga con firma',
                    value: 'signed_documentation',
                    sortable: false,
                    align: 'center',
                    width: 135
                },
                {
                    text: 'Expediente',
                    value: 'administrative_exp_documentation',
                    sortable: false,
                    align: 'center',
                    width: 100
                },
                {
                    text: 'Publicación',
                    value: 'publicinformations',
                    sortable: true,
                    align: 'center',
                    width: 210,
                    sort: publicInformationsSort
                },
            ]
        }),
        created: function() {
            eventBus.$on('search_change', (data) => {
                this.search = data;
            })
        },
        computed: {
            dataUrl: function(){
                return this.data_url || this.$store.state.territorial_detail.data_url
            },
            dataFolderPath: function() {
                return this.data_folder_path || this.$store.state.territorial_detail.data_folder_path
            },
            detailId: function() {
                return this.detail_id || this.$store.state.territorial_detail.detail_id
            },
            pageTitle: function() {
                return this.page_title || this.$store.state.territorial_detail.page_title
            }
        },
        mounted: function() {
            if (typeof this.detail_id !== 'undefined') {
                this.$store.commit('territorial_detail/setDataUrl', this.data_url)
                this.$store.commit('territorial_detail/setDataFolderPath', this.data_folder_path)
                this.$store.commit('territorial_detail/setDetailId', this.detail_id)
                this.$store.commit('territorial_detail/setPageTitle', this.page_title)
            }
            document.title = this.pageTitle
            this.getHistoryData(this.dataUrl, this.detailId)
        },
        methods: {
            customSearch: customSearch,
            getHistoryData: getHistoryData,
            apiCall: apiCall,
            getItems: getItems,
        }
    }
</script>

<style scoped>
.detail-title {
    background-color: #d9d9d9;
    text-align: left;
    font-size: 0.875rem;
    line-height: 1.4em;
    vertical-align: middle;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 16px;
}
</style>