var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item.signed_documentation_pending)?_c('img',{staticClass:"btn-icon",attrs:{"src":"/static/img/btn/descarga_confirma_gris.png","title":"Próxima publicación"}}):(_vm.item.signed_documentation)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
        name: _vm.route_name,
        params: {
            base_url: _vm.data_url,
            data_url: _vm.data_url + 'signed_documentation/',
            data_folder_path: _vm.data_folder_path,
            doc_id: _vm.item.signed_documentation,
            code: _vm.item.code,
            caption_bgcolor: _vm.caption_bgcolor,
            title_caption: 'DESCARGAR DOCUMENTO DILIGENCIADO CON FIRMA ELECTRÓNICA',
            show_download_dialog: false
        }
    },"custom":""},nativeOn:{"click":function($event){_vm.trackEvent('Abrir página descarga (con firma)', _vm.item.code)}}},[_c('img',{staticClass:"btn-icon",attrs:{"title":"Descargar con firma electrónica, documento no navegable","src":"/static/img/btn/descarga_confirma.png"}})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }