<template>

    <div>

        <!-- botones iniciativa -->
        <div class="initiative">
            <v-btn class="white--text rounded-0" :ripple="false" :elevation="0" min-width="50%" :color="public_color" inline @click="openByInitiative('public')">Iniciativa Pública</v-btn>
            <v-btn class="white--text rounded-0" :ripple="false" :elevation="0" min-width="50%" :color="private_color" inline @click="openByInitiative('private')">Iniciativa Privada</v-btn>
        </div>

        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :custom-filter="customSearch"
            :loading="loading"
            sort-by="last_procedure_date"
            :sort-desc="true"
            :items-per-page="-1"
            must-sort
            :header-props="{sortIcon: 'mdi-arrow-up-thick'}"
        >

            <!-- historial -->
            <template v-slot:item.id="{ item }">
                <History
                    :reference="item.reference"
                    :page_title="page_title"
                    :data_url="data_url"
                    :data_folder_path="data_folder_path"
                    :caption_bgcolor="app_title_bgcolor">
                </History>
            </template>

        </v-data-table>

    </div>

</template>

<script>
    import { eventBus } from '../main'
    import {
        customSearch,
        getProcedure, getData, getItems, apiCall, trackEvent
    } from '../modules/common'
    import History from './BaseHistory'

    export default {
        components: {
            History
        },
        name: 'PII',
        props: {
            data_url: String,
            data_folder_path: String,
            app_title_bgcolor: String,
            app_font_color: String
        },
        data: () => ({
            page_title: 'PROYECTOS DE INTERÉS INSULAR. TRANSPARENCIA',
            options: {},
            items: [],
            totalItems: 0,
            loading: true,
            search: '',
            page: 1,
            headers: [
                {
                    text: 'Referencia',
                    value: 'reference',
                    sortable: true,
                    align: 'left',
                    width: 125
                },
                {
                    text: 'Título',
                    value: 'denomination',
                    sortable: true
                },
                {
                    text: 'Promotor',
                    value: 'projector',
                    sortable: true
                },
                {
                    text: 'Localización/Municipio',
                    value: 'location',
                    sortable: true
                },
                {
                    text: 'Historial de trámites',
                    value: 'id',
                    sortable: false,
                    align: 'center'
                },
                {
                    text: 'Última tramitación',
                    value: 'last_procedure_date',
                    sortable: true,
                    align: 'center'
                },
            ],
            initiativeText: {
                'private': 'privada',
                'public': 'pública'
            },
            private_color: 'app_title_bgcolor',
            public_color: 'app_title_bgcolor'
        }),
        created: function() {
            eventBus.$emit('app_name_change', 'pii')
            eventBus.$on('search_change', (data) => {
                this.search = data
            })
            eventBus.$emit('page_title_change', this.page_title)
            eventBus.$emit('page_title_color_change', this.app_font_color)
            eventBus.$emit('page_title_shadow_change', this.app_title_shadow)
            eventBus.$emit('app_bar_color_change', this.app_title_bgcolor)
        },
        mounted: function() {
            let init = true
            try {
                // require history, document page?
                const ref = this.$route.query.ref
                const doc_ref = this.$route.query.doc_ref
                if (typeof ref !== 'undefined') {
                    init = false
                    this.$router.push({ name: 'home' }) // to return home with browser back
                    trackEvent.bind(this)('Historial de trámites', ref)
                    this.$router.push({
                        name: 'History',
                        params: {
                            reference: ref,
                            page_title: this.page_title,
                            data_url: this.data_url,
                            data_folder_path: this.data_folder_path
                        }
                    })
                } else if (typeof doc_ref !== 'undefined') {
                    init = false
                    this.$router.push({ name: 'home' }) // to return home with browser back
                    trackEvent.bind(this)('Documento', doc_ref)
                    this.$router.push({
                        name: 'Document',
                        params: {
                            base_url: this.data_url,
                            data_url: this.data_url + 'documentation/',
                            data_folder_path: this.data_folder_path,
                            code: doc_ref,
                            caption_bgcolor: this.caption_bgcolor,
                            title_caption: 'DESCARGAR DOCUMENTO'
                        }
                    })
                }
                // default: load main page
                if (init) {
                    document.title = this.page_title
                    this.openByInitiative(this.$store.state.pii_main.initiative || 'public')
                }
            } catch (e) {
                console.log(e)
            }
        },
        methods: {
            customSearch: customSearch,
            getData: getData,
            getItems: getItems,
            apiCall: apiCall,
            getProcedure: getProcedure,
            highlightInitiative: function (initiative) {
                switch (initiative) {
                    case 'private':
                        // this.private_color = this.app_title_bgcolor
                        this.private_color = '#47729d'
                        this.public_color = 'grey'
                        break
                    case 'public':
                        this.private_color = 'grey'
                        // this.public_color = this.app_title_bgcolor
                        this.public_color = '#47729d'
                }
            },
            openByInitiative: function (initiative) {
                this.highlightInitiative(initiative)
                this.$store.commit('pii_main/setInitiative', initiative)
                this.getData(this.data_url + `project?initiative=${initiative}`)
            }
        }
    }
</script>

<style scoped>
.initiative {
    width: 100%;
    text-align: center;
}
/* th v-data-table */
.v-card__actions {
  text-align: center;
  display: block;
}

>>> .v-data-table__wrapper>table>thead>tr>th {
    background-color: #d9d9d9 !important;
    color: #000 !important;
}
</style>