var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{style:('--caption-bgcolor:' + _vm.caption_bgcolor),attrs:{"headers":_vm.headers,"items":_vm.uniqueItems,"page":_vm.page,"search":_vm.search,"custom-filter":_vm.customSearch,"loading":_vm.loading,"sort-by":"resolution_date","sort-desc":true,"dense":"","item-class":"data-item","caption":_vm.page_title},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.documentation",fn:function(ref){
var value = ref.value;
return [_c('router-link',{attrs:{"to":{
                name: 'LandscapeDownload',
                params: {
                    data_url: _vm.data_url + 'documentation/',
                    data_folder_path: _vm.data_folder_path,
                    doc_id: value,
                    caption_bgcolor: _vm.caption_bgcolor
                }
            },"custom":""}},[_vm._v("Descarga de documentación")])]}},{key:"item.location_url",fn:function(ref){
            var value = ref.value;
return [_c('Location',{attrs:{"value":value,"img_size":'30px'}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }