<template>

    <v-data-table
        :headers="headers"
        :items="items"
        :page.sync="page"
        :search="search"
        :custom-filter="customSearch"
        :loading="loading"
        sort-by="registration"
        :sort-desc="true"
        :items-per-page="-1"
        dense
        item-class="data-item"
        :style="'--caption-bgcolor:' + caption_bgcolor"
    >

        <!-- historial -->
        <template v-slot:item.id="{ item }">
            <History
                :ga_action="item.reference + ' - ' + item.denomination"
                :reference="item.reference"
                :page_title="item.reference + '. ' + item.denomination"
                :data_url="data_url"
                :data_folder_path="data_folder_path"
                :caption_bgcolor="app_title_bgcolor">
            </History>
        </template>

        <!-- publicaciones en boletín -->
        <template #item.publicinformations="{ value }">
            <PublicInformations
                :data_folder_path="dataFolderPath"
                :value="value">
            </PublicInformations>
        </template>

        <!-- viewer -->
        <template #item.viewer_url="{ value }">
            <Location
                :value="value"
                :img_size="'30px'">
            </Location>
        </template>

        <!-- video -->
        <template #item.video_url="{ value }">
            <video
                v-if="value"
                preload="none" controls="true" height="90"
                @play="trackEvent('Visualización video', value)"
            >
                <source :src="data_folder_path + value" type="video/webm" />
                    Su navegador no soporta videos embebidos.
                    Haga clic <a :href="data_folder_path + value" target="_blank">aquí</a> para descargarlo.
            </video>
        </template>

    </v-data-table>

</template>

<script>
    import { eventBus } from '../main'
    import {
        trackEvent,
        customSearch,
        getData, getItems, apiCall,
    } from '../modules/common'
    import PublicInformations from './BasePublicInformations'
    import Location from './BaseLocation'
    import History from './BaseHistory'

    export default {
        name: 'DecPubSocInt',
        props: {
            data_url: String,
            data_folder_path: String,
            app_title_bgcolor: String,
            app_font_color: String
        },
        components: {
            PublicInformations,
            Location,
            History
        },
        data: function() {
            return {
                page_title: 'DECLARACIÓN DE INTERÉS PÚBLICO O SOCIAL. TRANSPARENCIA.',
                options: {},
                items: [],
                totalItems: 0,
                loading: true,
                search: '',
                page: 1,
                caption_bgcolor: '#50a972',
                headers: [
                    {
                        text: 'Registro solicitud',
                        value: 'registration',
                        sortable: true,
                        align: 'center',
                        width: 185
                    },
                    {
                        text: 'Referencia',
                        value: 'reference',
                        sortable: true,
                        align: 'center',
                        width: 130
                    },
                    {
                        text: 'Denominación',
                        value: 'denomination',
                        sortable: true
                    },
                    {
                        text: 'Administración origen',
                        value: 'source_organization',
                        sortable: true,
                        width: 200
                    },
                    {
                        text: 'Historial de trámites',
                        value: 'id',
                        sortable: false,
                        align: 'center'
                    },
                    {
                        text: 'Estado de la tramitación',
                        value: 'processing_status',
                        sortable: true,
                        width: 205
                    },
                    {
                        text: 'Documentos',
                        value: 'publicinformations',
                        sortable: true,
                        align: 'center',
                        width: 195,
                    },
                    {
                        text: 'Localización',
                        value: 'viewer_url',
                        sortable: false,
                        align: 'center'
                    },
                    {
                        text: 'Video',
                        value: 'video_url',
                        sortable: false,
                        align: 'center'
                    },
                ],
            }
        },
        created: function() {
            eventBus.$emit('app_name_change', 'decpubsocint')
            eventBus.$on('search_change', (data) => {
                this.search = data
            })
            eventBus.$emit('page_title_change', this.page_title)
            eventBus.$emit('page_title_color_change', this.app_font_color)
            eventBus.$emit('page_title_shadow_change', this.app_title_shadow)
            eventBus.$emit('app_bar_color_change', this.app_title_bgcolor)
        },
        watch: {
            loading: function() {
                if (!this.loading) {
                    try {
                        const ref = this.$route.query.ref
                        if (typeof ref !== 'undefined') {
                            // buscamos proyecto por referencia
                            const project = this.items.find(p => p.reference === ref)
                            trackEvent.bind(this)('Historial de trámites', ref)
                            this.$router.push({
                                name: 'History',
                                params: {
                                    ga_action: ref + ' - ' + project.denomination,
                                    reference: ref,
                                    page_title: ref + '. ' + project.denomination,
                                    data_url: this.data_url,
                                    data_folder_path: this.data_folder_path
                                }
                            })
                        }
                    } catch (e) {
                        console.log(e)
                    }
                }
            }
        },
        mounted: function() {
            document.title = this.page_title
            this.getData(this.data_url + 'main/')
            // try {
            //     console.log(this.items)
            //     const ref = this.$route.query.ref
            //     if (typeof ref !== 'undefined') {
            //         trackEvent.bind(this)('Historial de trámites', ref)
            //         this.$router.push({
            //             name: 'History',
            //             params: {
            //                 // :ga_action="item.reference + ' - ' + item.denomination"
            //                 reference: ref,
            //                 page_title: this.page_title,
            //                 // :page_title="item.reference + '. ' + item.denomination"
            //                 data_url: this.data_url,
            //                 data_folder_path: this.data_folder_path
            //             }
            //         })
            //     }
            // } catch (e) {
            //     console.log(e)
            // }
        },
        computed: {
            dataFolderPath: function() {
                return this.data_folder_path || this.$store.state.decpubsocint_history.data_folder_path
            }
        },
        methods: {
            customSearch: customSearch,
            trackEvent: trackEvent,
            getData: getData,
            getItems: getItems,
            apiCall: apiCall
        }
    }
</script>

<style scoped>
/* th v-data-table */
>>> .v-data-table__wrapper>table>thead>tr>th {
    background-color: #d9d9d9 !important;
    color: #000 !important;
}
</style>