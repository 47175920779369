export default function getCookiesConfig() {
	// binded with this (Vue)
	const vue = this
	return {
		current_lang: 'es',
		autoclear_cookies: true,                    // default: false
		page_scripts: true,                         // default: false

		// mode: 'opt-in',                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
		// delay: 0,                                // default: 0
		// auto_language: null,                     // default: null; could also be 'browser' or 'document'
		// autorun: true,                           // default: true
		// force_consent: false,                    // default: false
		// hide_from_bots: true,                    // default: true
		// remove_cookie_tables: false              // default: false
		// cookie_name: 'cc_cookie',                // default: 'cc_cookie'
		cookie_expiration: 365,                     // default: 182 (days)
		// cookie_necessary_only_expiration: 182    // default: disabled
		// cookie_domain: location.hostname,        // default: current domain
		// cookie_path: '/',                        // default: root
		// cookie_same_site: 'Lax',                 // default: 'Lax'
		// use_rfc_cookie: false,                   // default: false
		// revision: 0,                             // default: 0

		// onFirstAction: function(user_preferences, cookie) {
		// 	console.log('onFirstAction', cookie, user_preferences)
		// },

		// onAccept: function(cookie) {
		// 	console.log('onAccept', cookie.categories)
		// },

		onChange: function (cookie, /* changed_preferences */) {
			if (cookie.categories.includes(process.env.VUE_APP_GENDER_CATEGORY_NAME)) {
				if (vue.$cookies.get(process.env.VUE_APP_GENDER_COOKIE_NAME) == process.env.VUE_APP_GENDER_COOKIE_DENIED) {
					vue.$cookies.set(
						process.env.VUE_APP_GENDER_COOKIE_NAME,
						'',
						process.env.VUE_APP_GENDER_COOKIE_EXPIRES
					)
				}
			} else {
				vue.$cookies.set(
					process.env.VUE_APP_GENDER_COOKIE_NAME,
					process.env.VUE_APP_GENDER_COOKIE_DENIED,
					process.env.VUE_APP_GENDER_COOKIE_EXPIRES
				)
			}
		},

		gui_options: {
			consent_modal: {
				layout: 'cloud',               // box/cloud/bar
				position: 'bottom center',     // bottom/middle/top + left/right/center
				transition: 'slide',           // zoom/slide
				swap_buttons: false            // enable to invert buttons
			},
			settings_modal: {
				layout: 'box',                 // box/bar
				position: 'left',              // left/right
				transition: 'slide'            // zoom/slide
			}
		},

		languages: {
			'es': {
				//
				// capa 1
				//
				consent_modal: {
					title: 'Uso de cookies',
					description: 'Este sitio web utiliza cookies para optimizar su visita y para la recopilación de datos estadísticos. Puede denegar el uso de las mismas configurando adecuadamente su navegador. <button type="button" data-cc="c-settings" class="cc-link">Más información</button>',
					primary_btn: {
						text: 'Aceptar todas',
						role: 'accept_all'              // 'accept_selected' or 'accept_all'
					},
					secondary_btn: {
						text: 'Rechazar todas',
						role: 'accept_necessary'        // 'settings' or 'accept_necessary'
					}
				},
				//
				// capa 2
				//
				settings_modal: {
					title: 'Configuración de cookies',
					save_settings_btn: 'Guardar configuración',
					accept_all_btn: 'Aceptar todas',
					reject_all_btn: 'Rechazar todas',
					close_btn_label: 'Cerrar',
					cookie_table_headers: [
						{ col1: 'Nombre' },
						{ col2: 'Expiración' },
						{ col3: 'Descripción' }
					],
					blocks: [
						{
							title: 'Uso de las cookies',
							description: 'Este sitio web, al igual que la mayoría de los sitios en internet, usa cookies para mejorar y optimizar la experiencia del usuario. Para más información relativa a las cookies y otros datos sensibles, por favor lea nuestra <a href="/static/ayuda/ayuda_es/documents/cookies.html" target="_blank" class="cc-link">política de privacidad</a>.'
						},
						{
							title: 'Cookies estríctamente necesarias',
							description: 'Son aquellas cookies imprescindibles para el correcto funcionamiento de la página.',
							toggle: {
								value: 'necessary',
								enabled: true,
								readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
							},
							cookie_table: [
								{
									col1: 'cc_cookie',
									col2: '1 año',
									col3: 'Cookie que almacena el consentimiento del uso de las cookies.'
								},
								{
									col1: 'csrftoken',
									col2: '1 año',
									col3: 'Cookie incluida en las solicitudes HTTP para evitar los ataques debidos a la vulnerabilidad <a href="https://developer.mozilla.org/es/docs/Glossary/CSRF" target="_blank">Cross-Site Request Forgery</a>.'
								},
								{
									col1: 'cookiesession1',
									col2: '1 año',
									col3: 'Cookie que permite almacenar un identificador único para equilibrar la carga de trabajo de los servidores.'
								},
								// {
								// 	col1: 'sessionid',
								// 	col2: '2 semanas',
								// 	col3: 'Cookie de identificador de sesión, generada cuando el usuario se valida como usuario registrado.'
								// }
							]
						},
						{
							title: 'Cookies analíticas y de rendimiento',
							description: 'Estas cookies recopilan información sobre la experiencia de navegación del usuario en el sitio web, normalmente de forma anónima, aunque en ocasiones también permiten identificar de manera única e inequívoca al usuario con el fin de obtener informes sobre los intereses de los usuarios en los servicios que ofrece el sitio web.',
							toggle: {
								value: 'analytics',
								enabled: false,
								readonly: false
							},
							cookie_table: [
								{
									col1: '_ga',
									col2: '2 años',
									col3: 'Se usa para distinguir a los usuarios. Para más información acerca de las cookies de Google Analytics, consulte el <a href="https://developers.google.com/analytics/devguides/collection/ga4/cookie-usage" target="_blank">uso de las cookies en sitios web</a>.',
									domain: 'idegrancanaria.es'
								},
								{
									col1: '^_ga_',
									col2: '2 años',
									col3: 'Se usa para mantener el estado de la sesión. Para más información acerca de las cookies de Google Analytics, consulte el <a href="https://developers.google.com/analytics/devguides/collection/ga4/cookie-usage" target="_blank">uso de las cookies en sitios web</a>.',
									domain: 'idegrancanaria.es',
									is_regex: true
								}
							]
						},
						{
							title: 'Cookies demográficas',
							description: 'Cookies asociadas a datos demográficos.',
							toggle: {
								value: process.env.VUE_APP_GENDER_CATEGORY_NAME
							},
							cookie_table: [
								{
									col1: 'gender-cookie',
									col2: '2 años',
									col3: 'Esta cookie recopila el género con el que el usuario se identifica, para su uso estadístico.',
								},
							]
						},
						{
							title: 'Más información',
							description: 'Para cualquier consulta en relación a nuestra política de cookies y sus opciones, por favor <a class="cc-link" href="https://www.idegrancanaria.es/content/contactar" target="_blank">contacte con nosotros</a>.',
						}
					]
				}
			}
		}
	}
}