// initial state
const state = () => ({
  data_url: null,
  data_folder_path: null,
  detail_id: null,
  page_title: null
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setDataUrl (state, url) {
    state.data_url = url
  },
  setDataFolderPath (state, path) {
    state.data_folder_path = path
  },
  setDetailId (state, id) {
    state.detail_id = id
  },
  setPageTitle (state, title) {
    state.page_title = title
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}