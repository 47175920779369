import Vue from 'vue'
import App from './App'
import vuetify from './plugins/vuetify'
import index from './router/index'
import route_decpubsocint from './router/decpubsocint'
import route_landscape from './router/landscape'
import route_porn from './router/porn'
import route_opi from './router/opi'
import route_pii from './router/pii'
import route_pnenp from './router/pnenp'
import route_territorial from './router/territorial'
import route_pio from './router/pio'

import store from './store'

import VueAnalytics from 'vue-analytics'
import VueGTag from 'vue-gtag'

import VueCookies from 'vue-cookies'

Vue.config.productionTip = false

export const eventBus = new Vue();

const router_host = {
  // interés público y social
  'pre-interes-publico-social': {
    'ga_id': '', // UA-4915893-45
    'gtag_id': '', // 'G-5R6ZZJPDD9',
    'router': route_decpubsocint
  },
  'interes-publico-social': {
    'ga_id': '', // UA-4915893-45
    'gtag_id': 'G-5R6ZZJPDD9',
    'router': route_decpubsocint
  },
  // instrumentos paisaje
  'pre-instrumentos-paisaje': {
    'ga_id': '', // UA-4915893-46
    'gtag_id': '', // 'G-2ZSVV1176P',
    'router': route_landscape
  },
  'instrumentos-paisaje': {
    'ga_id': '', // UA-4915893-46
    'gtag_id': 'G-2ZSVV1176P',
    'router': route_landscape
  },
  // ordenación de recursos naturales
  'pre-ordenacion-recursos-naturales': {
    'ga_id': '', // 'UA-4915893-48',
    'gtag_id': '', // 'G-KF2MMH6R5R',
    'router': route_porn
  },
  'ordenacion-recursos-naturales': {
    'ga_id': '', // 'UA-4915893-48',
    'gtag_id': 'G-KF2MMH6R5R',
    'router': route_porn
  },
  // ordenanza provisional insular
  'pre-ordenanza-provisional-insular': {
    'ga_id': '',
    'gtag_id': '', // 'G-QSSK7QL6B2',
    'router': route_opi
  },
  'ordenanza-provisional-insular': {
    'ga_id': '',
    'gtag_id': 'G-QSSK7QL6B2',
    'router': route_opi
  },
  // planes insulares
  'pre-plan-insular': {
    'ga_id': '',
    'gtag_id': '', // 'G-K31FCZX8YX',
    'router': route_pio
  },
  'plan-insular': {
    'ga_id': '',
    'gtag_id': 'G-K31FCZX8YX',
    'router': route_pio
  },
  // proyectos de interés insular
  'pre-proyectos-interes-insular': {
    'ga_id': '',
    'gtag_id': '', // 'G-6P01RYSX28',
    'router': route_pii
  },
  'proyectos-interes-insular': {
    'ga_id': '',
    'gtag_id': 'G-6P01RYSX28',
    'router': route_pii
  },
  // ordenación de espacios protegidos
  'pre-ordenacion-espacios-protegidos': {
    'ga_id': '',
    'gtag_id': '', // 'G-3X6V713DNW',
    'router': route_pnenp
  },
  'ordenacion-espacios-protegidos': {
    'ga_id': '',
    'gtag_id': 'G-3X6V713DNW',
    'router': route_pnenp
  },
  // planes territoriales
  'pre-planesterritoriales': {
    'ga_id': '', // UA-4915893-15
    'gtag_id': '', // 'G-QJW86XJHJN',
    'router': route_territorial
  },
  'planesterritoriales': {
    'ga_id': '', // UA-4915893-15
    'gtag_id': 'G-QJW86XJHJN',
    'router': route_territorial
  }
}

const get_site_config = () => {
  const host_parts = window.location.host.split('.')
  if (router_host[host_parts[0]]) {
    return router_host[host_parts[0]]
  } else {
    return {
      'ga_id': '',
      'gtag_id': '',
      'router': index
    }
  }
}

const site_config = get_site_config()
const router = site_config.router
const ga_id = site_config.ga_id
const gtag_id = site_config.gtag_id

// activate Google Analytics only in production mode
if (process.env.NODE_ENV !== 'development') {
  if (gtag_id !== '') {
    Vue.use(VueGTag, {
      config: {
        id: gtag_id
      }
    })
  } else if (ga_id !== '') {
    Vue.use(VueAnalytics, {
      id: ga_id,
      router
    })
  }
}

Vue.use(VueCookies)

new Vue({
  vuetify,
  router: router,
  store: store,
  render: h => h(App)
}).$mount('#app')