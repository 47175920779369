<template>

    <div>
        
        <div :style="'--caption-bgcolor:' + caption_bgcolor" class="div-download">{{ title_caption }}</div>

        <!-- nota cautelar -->
        <!-- <div class="text-center">
            <v-dialog
                v-model="dialog"
                width="600">

                <v-card>
                    <v-card-title class="justify-center text-h5 grey lighten-2">
                        <img
                            :src="frontend_path + 'img/LogoCabildo_BN.png'"
                            width="55" />
                        <v-spacer></v-spacer>
                        <v-btn x-small plain @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text>
                        Advertimos que el historial de documentos recopilados que se alojan en esta página, en los casos en que no tienen diligencia manual o firma electrónica,
                        podrían presentar alguna discrepancia respecto a la versión custodiada por la Secretaría del Pleno del Cabildo de Gran Canaria,
                        órgano de referencia a estos efectos.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="download">
                            Continuar con la descarga
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div> -->

        <!-- page_title puede contener código HTML -->
        <div class="div-title" v-html="page_title"></div>

        <div v-if="root && root.path" class="div-fulldownload">
            <a
                @click="download">
                <img :src="downloadImageUrl()" class="download-icon" /><br/>
                <span>Descarga completa<br/>{{ title_full_download }}</span>
            </a>
            <span v-if="root.size">{{ root.size }}</span>
        </div>

        <br/>

        <div class="div-content">

            <v-treeview
                item-key="id"
                hoverable
                open-on-click
                :items="items"
                :open.sync="items_open"
                dense
            >
                <template v-slot:label="{ item }">
                    {{ item.name }}
                    &nbsp;&nbsp;
                    <a
                        v-if="item.path" :href="data_folder_path + item.path" target="_blank"
                        @click="trackEvent('Descarga individual ' + code, filename(item.path))">
                        <img :src="frontend_path + 'img/btn/pdf.png'" class="pdf-icon" />
                    </a>
                    &nbsp;<span v-if="item.size" class="size_text">{{ item.size }}</span>
                </template>
            </v-treeview>

        </div>

    </div>

</template>

<script>
    import {
        filename,
        trackEvent,
        getData_documentation as getData,
        getItemsPII_documentation as getItems,
        apiCall_documentation as apiCall
    } from '../modules/common'

    export default {
        name: 'Download',
        props: {
            base_url: String,
            data_url: String,
            data_folder_path: String,
            doc_id: Number,
            code: String,
            caption_bgcolor: String,
            title_caption: String,
        },
        data: () => ({
            frontend_path: process.env.VUE_APP_FRONTEND_PATH,
            page_title: '',
            items: [],
            // para forzar que los nodos de primer nivel se abran inicialmente
            items_open: [],
            loading: true,
            root: null,
            title_full_download: '',
            dialog: false
        }),
        mounted: function() {
            this.getData()
        },
        methods: {
            filename: filename,
            trackEvent: trackEvent,
            getData: getData,
            getItems: getItems,
            apiCall: apiCall,
            downloadImageUrl: function() {
                return this.frontend_path  + 'img/btn/descarga_sinfirma.png'
            },
            download: function() {
                this.trackEvent('Descarga completa ' + this.code, filename(this.root.path))
                this.dialog = false
                window.open(this.data_folder_path + this.root.path, '_blank');
            }
        }
    }
</script>

<style scoped>
.div-download {
    margin-top: 1rem;
    text-align: center;
    font-size: 1.1rem;
    background-color: var(--caption-bgcolor);
    height: 3rem;
    line-height: 1em;
    padding-top: 0.9em;
    width: 100vw;
    color: #fff;
}

.div-title {
    text-align: center;
    font-weight: bold;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 70%;
    font-size: 1rem;
    line-height: 1.2em;
}

.div-fulldownload {
    text-align: center;
    margin: auto;
    font-size: 0.8rem;
}

.div-content {
    width: 100%;
    margin: auto;
}


.size_text {
    font-size: x-small;
}

/* v-treeview */
.v-treeview {
    font-size: 0.8rem;
}
</style>

<style>
/* alto nodos */
.v-treeview--dense
.v-treeview-node,
.v-treeview-node--click,
.v-treeview-node__root {
    min-height: 25px !important;
}

/* para que se muestre todo el texto del nodo */
.v-treeview-node__label {
    white-space: initial !important;
}

.download-icon {
    width: 50px;
}

.pdf-icon {
    vertical-align: -5%;
    width: 1.5em;
}

.v-dialog > .v-card > .v-card__text {
    color: #000;
    text-align: justify;
}
</style>