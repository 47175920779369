var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"detail-title"},[_vm._v(_vm._s(_vm.page_title))]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1,"hide-default-footer":"","search":_vm.search,"custom-filter":_vm.customSearch,"loading":_vm.loading,"sort-by":"publicinformations","sort-desc":true,"dense":"","item-class":"data-item","must-sort":"","header-props":{sortIcon: 'mdi-arrow-up-thick'}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.code_ext}},[_vm._v(_vm._s(item.code))])]}},{key:"item.processing_status",fn:function(ref){
var item = ref.item;
return [_c('Location',{attrs:{"img_size":'15px',"value":item.viewer_url,"value_text":item.processing_status,"icon_color":_vm.caption_bgcolor,"label":item.processing_status_label}})]}},{key:"item.browsable_document_path",fn:function(ref){
var item = ref.item;
return [_c('BrowsableDocument',{attrs:{"data_folder_path":_vm.dataFolderPath,"value":item.browsable_document_path,"pending":item.browsable_document_pending,"icon_color":_vm.caption_bgcolor}})]}},{key:"item.unsigned_documentation",fn:function(ref){
var item = ref.item;
return [_c('UnsignedDownload',{attrs:{"data_url":_vm.dataUrl,"data_folder_path":_vm.dataFolderPath,"item":item,"_caption_bgcolor":_vm.caption_bgcolor,"caption_bgcolor":'#d9d9d9'}})]}},{key:"item.signed_documentation",fn:function(ref){
var item = ref.item;
return [_c('SignedDownload',{attrs:{"data_url":_vm.dataUrl,"data_folder_path":_vm.dataFolderPath,"item":item,"caption_bgcolor":'#d9d9d9'}})]}},{key:"item.administrative_exp_documentation",fn:function(ref){
var item = ref.item;
return [_c('AdministrativeExpedientDownload',{attrs:{"data_url":_vm.dataUrl,"data_folder_path":_vm.data_folder_path,"item":item,"caption_bgcolor":'#d9d9d9'}})]}},{key:"item.publicinformations",fn:function(ref){
var value = ref.value;
return [_c('PublicInformations',{attrs:{"data_folder_path":_vm.dataFolderPath,"value":value}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }