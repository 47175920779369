import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// Translation provided by Vuetify (typescript)
import es from 'vuetify/src/locale/es.ts'
import en from 'vuetify/src/locale/en.ts'

Vue.component('my-component', {
    methods: {
      changeLocale () {
        this.$vuetify.lang.current = 'es'
      },
    },
})
  
export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    lang: {
        locales: { es, en },
        current: 'es',
    },
});
