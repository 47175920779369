<template>

    <img v-if="item.unsigned_documentation_pending"
        class="btn-icon"
        src="/static/img/btn/descarga_sinfirma_gris.png"
        title="Próxima publicación" />

    <router-link
        v-else-if="item.unsigned_documentation"
        :to="{
            name: route_name,
            params: {
                base_url: data_url,
                data_url: data_url + 'unsigned_documentation/',
                data_folder_path: data_folder_path,
                doc_id: item.unsigned_documentation,
                code: item.code,
                caption_bgcolor: caption_bgcolor,
                title_caption: 'DESCARGAR DOCUMENTO SIN FIRMA ELECTRÓNICA',
                show_download_dialog: true
            }
        }"
        custom style="text-decoration:none"
        @click.native="trackEvent('Abrir página descarga (sin firma)', item.code)"
    >
        <img
            title="Descargar sin firma electrónica, ficheros desde índice"
            class="btn-icon"
            src="/static/img/btn/descarga_sinfirma.png" />
    </router-link>

</template>

<script>
import {
    trackEvent
} from '../modules/common'
export default {
    name: 'UnsignedDownload',
    props: {
        data_url: String,
        data_folder_path: String,
        item: Object,
        caption_bgcolor: String,
        route_name: {
            type: String,
            default: 'Download_unsigned'
        }
    },
    data: () => ({
        frontend_path: process.env.VUE_APP_FRONTEND_PATH
    }),
    methods:{
        trackEvent: trackEvent
    }
}
</script>
