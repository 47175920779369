var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item.administrative_exp_documentation_pending)?_c('img',{staticClass:"btn-icon",attrs:{"src":"/static/img/btn/expediente_gris.png","title":"Próxima publicación"}}):(_vm.item.administrative_exp_documentation)?_c('router-link',{attrs:{"to":{
        name: _vm.route_name,
        params: {
            base_url: _vm.data_url,
            data_url: _vm.data_url + 'administrative_exp_documentation/',
            data_folder_path: _vm.data_folder_path,
            doc_id: _vm.item.administrative_exp_documentation,
            code: _vm.item.code,
            caption_bgcolor: _vm.caption_bgcolor,
            is_expedient: true,
            title_caption: 'DESCARGAR EXPEDIENTE ADMINISTRATIVO'
        }
    },"custom":""}},[_c('img',{staticClass:"btn-icon",attrs:{"title":"Expediente administrativo","src":"/static/img/btn/expediente.png"}})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }