<template>

    <div>

        <div class="title-history" :style="'--subtitle-bgcolor: ' + subtitle_bgcolor">{{ history_title }}</div>

        <!--
            Historial de trámites
        -->
        <v-data-table
            id="history-table"
            :headers="history_headers"
            :items="history_items"
            :items-per-page="-1"
            hide-default-footer
            :search="search"
            :custom-filter="customSearch"
            :loading="loading"
            sort-by="order"
            dense
            item-class="data-item"
            must-sort
            :header-props="{sortIcon: 'mdi-arrow-up-thick'}"
        >

            <!-- publicaciones en boletín -->
            <template #item.publicinformations="{ value }">
                <PublicInformations
                    :data_folder_path="dataFolderPath"
                    :value="value">
                </PublicInformations>
            </template>

        </v-data-table>

        <!--
            Plano de planta general
        -->
        <v-data-table
            id="blueprint-table"
            :headers="isMobile ? blueprint_headers_mobile : blueprint_headers"
            :hide-default-header="isMobile"
            :items="blueprint_items"
            :items-per-page="-1"
            hide-default-footer
            dense
            item-class="data-item"
        >
            <template #item.blueprint_url="{ item }">
                <a
                    v-if="item.blueprint_url"
                    :href="data_folder_path + item.blueprint_url"
                    target="_blank" title="Clic para abrir en nueva ventana"
                    class="plan"
                >
                    <img id="img-plano-general" :src="data_folder_path + item.blueprint_thumbnail_url" />
                </a>
                <div v-else class="unavailable-plan plan">Plano pendiente de publicación</div>
            </template>
        </v-data-table>

    </div>

</template>

<script>
    import { eventBus } from '../main'
    import {
        customSearch,
        getMainHistoryData,
        apiCall, getItems,
    } from '../modules/common'
    import PublicInformations from './BasePublicInformations'

    export default {
        name: 'DecPubSocIntHistory',
        components: {
            PublicInformations
        },
        props: {
            reference: String,
            page_title: String,
            data_url: String,
            data_folder_path: String,
            caption_bgcolor: String,
        },
        data: () => ({
            options: {},
            history_title: '',
            history_items: [],
            history_total_items: 0,
            loading: true,
            search: '',
            history_headers: [
                {
                    text: 'Trámite',
                    value: 'processing_status',
                    sortable: false
                },
                {
                    text: 'Documento',
                    value: 'publicinformations',
                    sortable: false,
                    align: 'center',
                    width: 215
                },
            ],
            blueprint_headers: [
                {
                    text: 'Plano de planta general',
                    value: 'blueprint_url',
                    sortable: false
                }
            ],
            blueprint_headers_mobile: [
                {
                    value: 'blueprint_url',
                    sortable: false
                }
            ],
            blueprint_items: [],
            subtitle_bgcolor: '#d9d9d9'
        }),
        watch: {
            history_items() {
                this.blueprint_items.push(
                    {
                        blueprint_url: this.history_items[0].blueprint_url,
                        blueprint_thumbnail_url: this.history_items[0].blueprint_thumbnail_url
                    }
                );
            }
        },
        created: function() {
            eventBus.$on('search_change', (data) => {
                this.search = data;
            })
        },
        updated: function() {
            // history_title
            document.title = this.page_title
            this.history_title = this.page_title
        },
        mounted: function() {
            if (typeof this.reference !== 'undefined') {
                this.$store.commit('decpubsocint_history/setReference', this.reference)
                this.$store.commit('decpubsocint_history/setPageTitle', this.page_title)
                this.$store.commit('decpubsocint_history/setDataUrl', this.data_url)
                this.$store.commit('decpubsocint_history/setDataFolderPath', this.data_folder_path)
                this.$store.commit('decpubsocint_history/setCaptionBgColor', this.caption_bgcolor)
            }
            this.getMainHistoryData(this.dataUrl, this.ref)
        },
        computed: {
            isMobile: function() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return true
                    case 'sm': return true
                    case 'md': return false
                    case 'lg': return false
                    case 'xl': return false
                    default: return false
                }
            },
            dataUrl: function() {
                return this.data_url || this.$store.state.decpubsocint_history.data_url
            },
            dataFolderPath: function() {
                return this.data_folder_path || this.$store.state.decpubsocint_history.data_folder_path
            },
            ref: function() {
                return this.reference || this.$store.state.decpubsocint_history.reference
            },
            captionBgColor: function() {
                return this.caption_bgcolor || this.$store.state.decpubsocint_history.caption_bgcolor
            }
        },
        methods: {
            customSearch: customSearch,
            getMainHistoryData: getMainHistoryData,
            apiCall: apiCall,
            getItems: getItems
        }
    }
</script>

<style scoped>
.title-history {
    background-color: var(--subtitle-bgcolor);
    font-size: 0.875rem;
    line-height: 1.4rem;
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-top: 2rem;
}

.title-details {
    color: #000;
    /* simulamos negrita */
    text-shadow: 0.01rem 0.01rem #000;
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    min-height: 1.2rem;
    line-height: 0.9em;
    margin-top: 3rem;
    padding-left: 16px;
}

/* eliminamos padding de las celdas de la tabla de detalle */
#detail-table::v-deep > .v-data-table__wrapper > table > tbody > tr > td,
#detail-table::v-deep > .v-data-table__wrapper > table > tbody > tr > th,
#detail-table::v-deep > .v-data-table__wrapper > table > thead > tr > td,
#detail-table::v-deep > .v-data-table__wrapper > table > thead > tr > th,
#detail-table::v-deep > .v-data-table__wrapper > table > tfoot > tr > td,
#detail-table::v-deep > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0;
}

>>> td:has(> *.plan) {
    border: 1px solid black !important;
}
.unavailable-plan {
    width: 100%;
    height: 400px;
    line-height: 400px;
    text-align: center;
    font-size: larger;
}

/* th v-data-table*/
>>> .v-data-table__wrapper>table>thead>tr>th {
    background-color: #1E6AA8 !important;
    color: #fff !important;
}
>>> .v-data-table tbody>tr {
    height: 55px !important;
}

/* @media only screen and (max-width: 600px) { */
@media (pointer:none), (pointer:coarse) {
    >>>.theme--light.v-btn {
        color: #fff !important;
    }
    #history-table {
        width: 100%;
    }
    #blueprint-table {
        width: 100%;
        margin-top: 20px;
    }
    #img-plano-general {
        width: 100%;
    }
}
/* @media only screen and (min-width: 601px) { */
@media (pointer:fine) {
    #history-table {
        position: absolute;
        width: 55%;
    }
    #blueprint-table {
        position: absolute;
        right: 0;
        width: 45%;
    }
    #img-plano-general {
        height: 500px;
    }
}
</style>