import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '@/components/Territorial'
import basedownload from '@/components/BaseDownload'
import history from '@/components/TerritorialHistory'
import detailhistory from '@/components/TerritorialDetailHistory'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      // relación de planes y su última modificación de trámite
      path: '/',
      name: 'home',
      component: home,
      props: {
        data_url: process.env.VUE_APP_TERRITORIAL_DATA_URL,
        data_folder_path: process.env.VUE_APP_TERRITORIAL_DATA_FOLDER_PATH,
        app_font_color: process.env.VUE_APP_TERRITORIAL_FONT_COLOR,
        app_title_bgcolor: process.env.VUE_APP_TERRITORIAL_TITLE_BGCOLOR,
        app_title_shadow: process.env.VUE_APP_TERRITORIAL_TITLE_SHADOW
      }
    },
    {
      // path: '/unsigned_documentation',
      path: '/?ucode=:code',
      name: 'Download_unsigned',
      component: basedownload,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    },
    {
      // path: '/signed_documentation',
      path: '/?scode=:code',
      name: 'Download_signed',
      component: basedownload,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    },
    {
      // path: '/administrative_exp_documentation',
      path: '/?expcode=:code',
      name: 'Download_admexp',
      component: basedownload,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    },
    {
      // historial de trámites
      // path: '/history',
      path: '/?ref=:reference',
      name: 'History',
      component: history,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    },
    {
      // detalles de plan
      path: '/detail',
      name: 'Detail',
      component: detailhistory,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    }
  ]
})

export default router
