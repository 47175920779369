import { render, staticRenderFns } from "./BasePublicInformations.vue?vue&type=template&id=3046659a&scoped=true"
import script from "./BasePublicInformations.vue?vue&type=script&lang=js"
export * from "./BasePublicInformations.vue?vue&type=script&lang=js"
import style0 from "./BasePublicInformations.vue?vue&type=style&index=0&id=3046659a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3046659a",
  null
  
)

export default component.exports