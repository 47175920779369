import axios from 'axios'
import _ from 'lodash'

export function unsignedString(item) {
    return item.reference + item.code_unsigned
}

export function signedString(item) {
    return item.reference + item.code_signed
}

export function trackEvent(category, action, label, value) {
    // this = componente
    // Invocar con .bind(this) desde dentro de los componentes
    console.group('Google Analytics event')
    console.log(category, action, label, value)
    try {
        if (this.$ga) {
            console.log('UA')
            this.$ga.event(category, action, label, value)
        } else if (this.$gtag) {
            console.log('GA4')
            this.$gtag.event(category, {
                'event_item': action,
                'event_label': label,
                'event_value': value
            })
        }
    } catch (e) {
        console.log('[ trackEvent ]', e)
    }
    console.groupEnd()
}

export function trackGenderEvent() {
    // this = componente
    // Invocar con .bind(this) desde dentro de los componentes
    let gender = null
    try {
        gender = this.$cookies.get(process.env.VUE_APP_GENDER_COOKIE_NAME)
        if (gender == process.env.VUE_APP_GENDER_COOKIE_DENIED) {
            gender = null
        }
    } catch (e) {
        gender = null
    }
    if (gender != null) {
      trackEvent.bind(this)('rol-usuario', gender)
    }
}

export function trackEvent_ua(category, action, label, value) {
    console.group('Universal Analytics')
    console.log('Event', category, action, label, value)
    if (this.$ga) {
        this.$ga.event(category, action, label, value)
        console.log('Event sent')
    }
    console.groupEnd()
}

export function trackEvent_ga4(category, action, label, value) {
    console.group('Google Analytics 4')
    console.log('Event', category, action, label, value)
    if (this.$gtag) {
        this.$gtag.event(action, {
            'event_category': category,
            'event_label': label,
            'value': value
        })
        console.log('Event sent')
    }
    console.groupEnd()
}

export function customSearch(value, search, item) {
    const check = function(v) {
        if (v) {
            const target = v.toString().toLowerCase()
            if (search.normalize('NFD').match(/[\u0300-\u036f]/gi)) {
                // el texto contiene especiales
                return target.includes(search.toLowerCase())
            } else {
                // el texto no contiene caracteres especiales
                return target.normalize('NFD').replace(/[\u0300-\u036f]/gi, '').includes(search.toLowerCase())
            }
        }
    }
    if (Array.isArray(value)) {
        // publicinformations
        // return value.some(pubinf =>
        //     Object.values(pubinf).some(v =>
        //         v && v.toString().toLowerCase().includes(search.toLowerCase())
        //     )
        // )
        return value.some(pubinf =>
            Object.values(pubinf).some(v =>
                check(v)
            )
        )
    }
    return Object.values(item).some(v =>
        check(v)
    )
}

export function getData(data_url, reference) {
    var me = this
    this.loading = true
    this.apiCall(data_url, reference).then(data => {
        me.items = data.items
        me.totalItems = data.total
        me.loading = false
    })
}

export function getMainHistoryData(data_url, reference) {
    // historial de trámites
    var me = this
    this.loading = true
    this.apiCall(data_url + 'main_history/', reference).then(data => {
        me.history_items = data.items
        me.history_total_items = data.total
        me.loading = false
    })
}

export function getHistoryData(data_url, detail_id) {
    // historial de trámites de una alteración
    var me = this
    this.loading = true
    this.apiCall(data_url + 'history/', detail_id).then(data => {
        me.history_items = data.items
        me.history_total_items = data.total
        me.loading = false
    })
}

export function getDetailData(data_url, reference) {
    // alteraciones de un plan
    var me = this
    this.loading = true
    this.apiCall(data_url + 'detail/', reference).then(data => {
        me.detail_items = data.items
        me.detail_total_items = data.total
        me.loading = false
    })
}

export function getItems(callback, path, param) {
    // let url = this.data_url
    //         + (typeof path !== 'undefined' ? path : '')
    //         + (typeof param !== 'undefined' ? param : '')
    //         + '?'
    let url = path
            + (typeof param !== 'undefined' ? param : '')
            + (path.indexOf('?') >= 0 ? '' : '?')
    // get data
    axios.get(url)
        .then(response => {
            callback(response.data)
        })
        .catch(function (error) {
            console.log('AXIOS error: ', error)
        })
}

export function apiCall(path, param) {
    var me = this
    return new Promise((resolve) => {
        me.getItems(function(data) {
            // remove duplicates (keeping order)
            me.items = _.uniqWith(data, _.isEqual)

            let items = me.items
            const total = me.items.length

            setTimeout(() => {
                resolve({
                    items,
                    total,
                })
            }, 500)
        }, path, param)
    })
}

// function loop_children(children) {
//     var arr = []
//     if (children) {
//         for (const child of children) {
//             arr.push(child.id)
//             arr = arr.concat(loop_children(child.children))
//         }
//     }
//     return arr
// }

function loop_children(children) {
    var arr = []
    if (children) {
        for (const child of children) {
            if (child.expanded)  {
                arr.push(child.id)
            }
            arr = arr.concat(loop_children(child.children))
        }
    }
    return arr
}

export function getData_documentation() {
    var me = this
    this.loading = true
    this.apiCall().then(data => {
        me.root = data.root_item
        me.page_title = data.root_item.name
        me.items = data.root_item.children
        // open-all del treeview de Vuetify no abre
        // los nodos de primer nivel. De esta forma lo forzamos.
        // me.items_open = data.root_item.children.map(
        //     item => {
        //         item.name
        //     }
        // )
        // me.items_open = []
        // for (const item of data.root_item.children) {
        //     if (item.children.length > 0) {
        //         me.items_open.push(item.id)
        //     }
        // }
        me.items_open = loop_children(data.root_item.children)
        me.loading = false
    })
}

// export function getItems_documentation(callback) {
//     let url = this.data_url + this.doc_id
//     // get data
//     axios.get(url)
//         .then(response => {
//             callback(response.data)
//         })
//         .catch(function (error) {
//             console.log('AXIOS error: ', error)
//         })
// }

export function getItems_documentation(callback) {
    getProcedure.bind(this)(this.code, (proc) => {
        let url
        if (this.data_url.match(/\/signed/i)) {
            url = this.data_url + proc.signed_documentation
        } else if (this.data_url.match(/unsigned/i)) {
            url = this.data_url + proc.unsigned_documentation
        } else if (this.data_url.match(/administrative_exp/i)) {
            url = this.data_url + proc.administrative_exp_documentation
        }
        // get data
        axios.get(url)
            .then(response => {
                callback(response.data)
            })
            .catch(function (error) {
                console.log('AXIOS error: ', error)
            })
     })
}

export function getItemsPII_documentation(callback) {
    getProcedure.bind(this)(this.code, (proc) => {
        // let url
        // if (this.data_url.match(/\/signed/i)) {
        //     url = this.data_url + proc.signed_documentation
        // } else if (this.data_url.match(/unsigned/i)) {
        //     url = this.data_url + proc.unsigned_documentation
        // } else if (this.data_url.match(/administrative_exp/i)) {
        //     url = this.data_url + proc.administrative_exp_documentation
        // }
        // // get data
        // axios.get(url)
        //     .then(response => {
        //         callback(response.data)
        //     })
        //     .catch(function (error) {
        //         console.log('AXIOS error: ', error)
        //     })
        callback(proc)
     }, 'documentation')
}

export function apiCall_documentation() {
    var me = this
    return new Promise((resolve) => {
        me.getItems(function(data) {
            let root_item = data

            setTimeout(() => {
                resolve({
                    root_item,
                })
            }, 500)
        })
    })
}

export function getProcedure(ref, callback, subpath) {
    // let url = this.data_url + `procedure/${ref}`
    if (typeof subpath == 'undefined') {
        subpath = 'procedure'
    }
    let url = this.base_url + `${subpath}/${ref}`
    // get data
    axios.get(url)
        .then(response => {
            callback(response.data)
        })
        .catch(function (error) {
            console.log('AXIOS error: ', error)
        })
}

export function filename(path) {
    return path.replace(/^.*[\\/]/, '').replace(/\.(rar|zip)$/i, '')
}

export function publicInformationsSort(x, y) {
    // [0]: date
    // [1]: bulletin name
    // [2]: bulletin number

    var pattern = /(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})\s+(.+)-?([0-9]*)/
    var m, a, b, x_date, y_date
    try {
        m = x[0].text.match(pattern)
        x_date = new Date(m[1] + '/' + m[3] + '/' + m[5])
        a = [x_date, m[4], m[5]]
    } catch (e) {
        a = [new Date('1900/01/01'), '', '0']
    }
    try {
        m = y[0].text.match(pattern)
        y_date = new Date(m[1] + '/' + m[3] + '/' + m[5])
        b = [y_date, m[4], m[5]]
    } catch (e) {
        b = [new Date('1900/01/01'), '', '0']
    }

    a[2] = parseInt(a[2])
    b[2] = parseInt(b[2])
    if (a[0].getTime() > b[0].getTime()) {
        // a > b
        return 1
    } else if (a[0].getTime() < b[0].getTime()) {
        // a < b
        return -1
    } else {
        if (a[1] == b[1] && a[2] > b[2]) {
            // a > b
            return 1
        } else if (a[1] == b[1] && a[2] < b[2]) {
            // a < b
            return -1
        } else {
            // a == b
            return 0
        }

    }
}
