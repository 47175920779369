<template>

  <v-dialog
    v-model="show_dialog"
    width="500"
  >
    <v-card class="gender-container">
      <v-card-title>
        ¿Con qué género se identifica?
        <v-spacer></v-spacer>
        <v-btn x-small plain @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-subtitle>Recopilamos este dato para su uso estadístico.</v-card-subtitle>
      <v-card-text>
        <v-form ref="gender-form">
          <v-select
            v-model="gender"
            :items="items"
            :rules="rules"
            label="Seleccione una opción..."
            required
          ></v-select>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="#154a75" class="mt-4 white--text" @click="accept"
          title="Su elección se utilizará para uso estadístico">Aceptar</v-btn>
        <v-btn
          color="#154a75" class="mt-4 white--text" @click="deny"
          title="No le volveremos a preguntar"> Denegar </v-btn>
      </v-card-actions>
      <div class="bottom-text">Adaptación al marco estratégico por la igualdad.<br/>Gran Canaria infinita 2020-2030</div>
    </v-card>
  </v-dialog>

</template>

<script>
import { trackGenderEvent } from '../modules/common';

export default {
  data: () => ({
    dialog: true,
    gender: null,
    items: [
      "Masculino",
      "Femenino",
      "No Binario",
      "Otro",
      "Prefiero no responder",
    ],
    rules: [
      (value) => {
        if (value) return true;
        return 'Seleccione una opción válida';
      },
    ],
  }),

  computed: {
    show_dialog: {
      get: function() {
        if (!this.dialog) {
          return false
        } else {
          return this.$cookies.get(process.env.VUE_APP_GENDER_COOKIE_NAME) == null
        }
      },
      set: function(/*newValue*/) {
        // this.dialog = newValue
      }
    }
  },

  methods: {

    accept() {
      const valid = this.$refs['gender-form'].validate()
      if (valid) {
        this.$cookies.set(
          process.env.VUE_APP_GENDER_COOKIE_NAME, this.gender,
          process.env.VUE_APP_GENDER_COOKIE_EXPIRES
        )
        trackGenderEvent.bind(this)()

        // accept cc_cookie category: demographic
        this.$cc.accept(process.env.VUE_APP_GENDER_CATEGORY_NAME)

        this.close()
      }
    },

    deny() {
      // reject cc_cookie category: demographic
      this.$cc.accept(process.env.VUE_APP_GENDER_CATEGORY_NAME)

      this.$cookies.set(
        process.env.VUE_APP_GENDER_COOKIE_NAME,
        process.env.VUE_APP_GENDER_COOKIE_DENIED,
        process.env.VUE_APP_GENDER_COOKIE_EXPIRES
      )

      this.close()
    },

    close() {
      this.dialog = false
    },

  },
}
</script>

<style scoped>
.v-card__actions {
  text-align: center;
  display: block;
}
.bottom-text {
  padding-left:3rem;
  padding-right:3rem;
  font-size:small;
  text-align: center;
}
/* @media only screen and (max-width: 600px) { */
@media (pointer:none), (pointer:coarse) {
  .gender-container {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }
}
</style>