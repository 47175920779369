// initial state
const state = () => ({
  initiative: null
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setInitiative (state, initiative) {
    state.initiative = initiative
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}