<template>

  <div>

    <div class="div-head">
      <!-- logo cabildo -->
      <a href="http://www.grancanaria.com/" target="_blank">
        <img
          :src="frontend_path + 'img/LogoCabildo_BN.png'"
          width="95" />
      </a>
    </div>

    <v-app>

      <v-app-bar :color="app_bar_color" :min-height="app_bar_height" :max-height="app_bar_height">

        <v-toolbar-title
          class="page-title"
          :style="'--page-title-color: ' + page_title_color + ';--page-title-shadow:' + page_title_shadow"
        >{{ page_title }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- links/tools (desktop version) -->
        <div v-if="!isMobile" class="div-links">

          <!-- links -->
          <a v-for="(item, index) in links" :key="index"
            :href="item.href"
            :title="item.text"
            class="app-bar-link"
            :style="'--app-bar-link-color: ' + page_title_color"
            target="_blank">
            <button v-if="item.text=='Cookies'" type="button" data-cc="c-settings" title="Configuración de cookies">{{ item.text }}</button>
            <span v-else>{{ item.text }}</span>
          </a>

          <!-- home -->
          <v-btn icon @click="goHome" :color="v_btn_color">
            <v-icon>mdi-home</v-icon>
          </v-btn>

          <!-- print -->
          <v-btn icon @click="printPage" :color="v_btn_color">
            <v-icon>mdi-printer</v-icon>
          </v-btn>

          <!-- search -->
          <v-btn icon @click="search_visible = !search_visible" :color="v_btn_color">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>

        </div>

        <!-- links/tools (mobile version) -->
        <v-menu v-if="isMobile" offset-y eager>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" :color="app_bar_color">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list>

            <!-- links -->
            <v-list-item
              v-for="(item, index) in links"
              :key="index"
              :title="item.title"
              :href="item.href"
              :data-cc="item.text=='Cookies' ? 'c-settings' : ''"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>

            <!-- home -->
            <v-list-item icon @click="goHome" :color="v_btn_color">
              <v-icon>mdi-home</v-icon>
            </v-list-item>

            <!-- print -->
            <v-list-item icon @click="printPage" :color="v_btn_color">
              <v-icon>mdi-printer</v-icon>
            </v-list-item>

            <!-- search -->
            <v-list-item icon @click="search_visible = !search_visible" :color="v_btn_color">
              <v-icon>mdi-magnify</v-icon>
            </v-list-item>

          </v-list>
        </v-menu>

        <!-- last update date -->
        <div
          class="last-update-date-div"
          :style="'--last-update-date-color: ' + page_title_color"
        >Última actualización de la página: {{ last_modified_date }}</div>

      </v-app-bar>

      <v-main>
        <v-text-field
          v-if="search_visible"
          autofocus
          clearable
          filled
          v-model="search"
          label="Buscar"
          class="mx-4"
        ></v-text-field>

        <router-view></router-view>
      </v-main>

      <GenderRequest/>

    </v-app>

    <!-- <div id="cookies-config-div" :style="cssProps">
      <button type="button" data-cc="c-settings" title="Configuración de cookies">
        <v-icon class="cookies-config" size="48">mdi-cookie</v-icon>
      </button>
    </div> -->

  </div>

</template>

<script>
import Vue from 'vue'

import { eventBus } from './main'
import axios from 'axios'

import GenderRequest from './components/GenderRequest.vue'
import { trackGenderEvent } from './modules/common'

import 'vanilla-cookieconsent/dist/cookieconsent.css'
import 'vanilla-cookieconsent/dist/cookieconsent.js'
import getCookiesConfig from './modules/cookieConsentConfig.js'

export default {
  components: { GenderRequest },
  name: 'App',

  data: () => ({
    // valores a establecer desde el componente principal
    // de cada web mediante $emit
    page_title: '',
    page_title_color: 'rgb(0,0,0)',
    page_title_shadow: '2px 2px #000',
    app_bar_color: '',
    app_name: '',

    v_btn_color: '#fff',
    app_bar_height: 84,

    frontend_path: process.env.VUE_APP_FRONTEND_PATH,

    search_visible: false,
    search: '',

    last_modified_date: '',
    
    links: [
      {
        text: 'IDEGranCanaria',
        title: 'Ir a IDEGranCanaria',
        disabled: false,
        href: 'http://www.idegrancanaria.es',
      },
      {
        text: 'El Cabildo',
        title: 'Ir a la web del Cabildo de Gran Canaria',
        disabled: false,
        href: 'https://cabildo.grancanaria.com/el-cabildo',
      },
      {
        text: 'Contacto',
        title: 'Contactar a través de e-mail',
        disabled: false,
        href: 'mailto:sit@grancanaria.com',
      },
      {
        text: 'Cookies',
        title: 'Configuración de cookies',
        disabled: false,
        // href: '#'
      }
    ]

  }),

  watch: {
    search: function(newVal) {
      eventBus.$emit('search_change', newVal)
    }
  },

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
        default: return false
      }
    }
  },

  created: function() {
    // cookies
    trackGenderEvent.bind(this)()

    eventBus.$on('app_name_change', (name) => {
      this.app_name = name
    })
    eventBus.$on('page_title_change', (title) => {
      this.page_title = title
    })
    eventBus.$on('page_title_color_change', (color) => {
      this.page_title_color = this.v_btn_color = color
    })
    eventBus.$on('page_title_shadow_change', (text_shadow) => {
      this.page_title_shadow = text_shadow
    })
    eventBus.$on('app_bar_color_change', (color) => {
      this.app_bar_color = color
    })
  },

  mounted: function() {
    // cookies consent
    const cc = window.initCookieConsent()
    Vue.prototype.$cc = cc
    const ccConfig = getCookiesConfig.bind(this)()
    cc.run(ccConfig)

    this.getLastModifiedDate()
  },

  methods: {
    goHome() {
      this.$router.push({name: 'home'})
    },
    printPage() {
      window.print()
    },
    getLastModifiedDate() {
      axios.get('/last_modified_date/?app=' + this.app_name)
          .then(response => {
            let d = new Date(response.data.last_modified_date)
            this.last_modified_date = d.toLocaleDateString('es-ES')
          })
          .catch(function (error) {
              console.log('AXIOS error: ', error)
          })
    }
  }

}
</script>

<style>
@import url('https://fonts.cdnfonts.com/css/century-gothic');
@import url('https://fonts.cdnfonts.com/css/century-gothic-paneuropean');

body * {
  font-family: 'Century Gothic', sans-serif !important;
}

/* alto toolbar (evita descolocación texto última actualización) */
/* v-app-bar: min-height = max-height */
.v-toolbar__content {
  height: 84px !important;
}

.page-title {
  color: var(--page-title-color) !important;
}

/* intermitencia color de fondo líneas tablas */
/* tbody tr:nth-of-type(even) {
  background-color: #f2f2f2;
} */

/* cellspacing */
.v-data-table > .v-data-table__wrapper > table {
  border-spacing: 1px !important;
}

.btn-icon {
  width: 25px;
}
</style>

<style scoped>
.div-head {
  margin: 0;
  height: 94px;
  background-image: url(/static/img/Cabecera_visor.jpg);
}

.div-links {
  position: absolute;
  margin-top: -8px;
  margin-right: -8px;
  top: 0;
  right: 0;
}

.div-links a {
  font-size: 0.8rem;
  margin-right: 20px;
}

.v-toolbar * {
  color: #fff;
  text-align: left;
}

.v-toolbar__title {
  text-shadow: var(--page-title-shadow);
}

/* pagination items */
.v-list-item__title {
  font-size: 0.9rem;
}

.last-update-date-div {
  font-size: 0.75rem;
  position: absolute;
  right: 20px;
  bottom: 0;
  color: var(--last-update-date-color);
}
 
.app-bar-link {
  font-family: 'Century Gothic', sans-serif !important;
  font-size: x-small;
  text-decoration: none;
  text-transform: capitalize;
  white-space: nowrap;
  color: var(--app-bar-link-color) !important;
}

>>> .theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon {
    color: rgba(0, 0, 0, 0.6);
}

/* #cookies-config-div {
  position: fixed;
  bottom: 10px;
  left: 10px;
}

.cookies-config {
  opacity: 30%;
  color: var(--cookies-config-color);
}

.cookies-config:hover {
  opacity: 100%;
} */

/* @media only screen and (max-width: 600px) { */
@media (pointer:none), (pointer:coarse) {
  >>>.theme--light.v-btn {
      color: #fff;
  }
  /* .cookies-config {
    opacity: 100%;
    color: var(--cookies-config-color);
  } */
}
</style>