var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{style:('--caption-bgcolor:' + _vm.caption_bgcolor),attrs:{"headers":_vm.headers,"items":_vm.items,"page":_vm.page,"search":_vm.search,"custom-filter":_vm.customSearch,"loading":_vm.loading,"sort-by":"registration","sort-desc":true,"items-per-page":-1,"dense":"","item-class":"data-item"},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('History',{attrs:{"ga_action":item.reference + ' - ' + item.denomination,"reference":item.reference,"page_title":item.reference + '. ' + item.denomination,"data_url":_vm.data_url,"data_folder_path":_vm.data_folder_path,"caption_bgcolor":_vm.app_title_bgcolor}})]}},{key:"item.publicinformations",fn:function(ref){
var value = ref.value;
return [_c('PublicInformations',{attrs:{"data_folder_path":_vm.dataFolderPath,"value":value}})]}},{key:"item.viewer_url",fn:function(ref){
var value = ref.value;
return [_c('Location',{attrs:{"value":value,"img_size":'30px'}})]}},{key:"item.video_url",fn:function(ref){
var value = ref.value;
return [(value)?_c('video',{attrs:{"preload":"none","controls":"true","height":"90"},on:{"play":function($event){return _vm.trackEvent('Visualización video', value)}}},[_c('source',{attrs:{"src":_vm.data_folder_path + value,"type":"video/webm"}}),_vm._v(" Su navegador no soporta videos embebidos. Haga clic "),_c('a',{attrs:{"href":_vm.data_folder_path + value,"target":"_blank"}},[_vm._v("aquí")]),_vm._v(" para descargarlo. ")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }