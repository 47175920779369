var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
        name: _vm.route_name,
        params: {
            data_url: _vm.data_url,
            reference: _vm.reference,
            data_folder_path: _vm.data_folder_path,
            caption_bgcolor: _vm.caption_bgcolor,
            page_title: _vm.page_title,
            history_title: _vm.history_title,
            detail_title: _vm.detail_title,
        }
    },"custom":""},nativeOn:{"click":function($event){return _vm.trackEvent('Historial de trámites', _vm.ga4Action)}}},[_c('img',{staticClass:"btn-icon",attrs:{"src":"/static/img/btn/historial.png","title":"Historial de trámites"}})])}
var staticRenderFns = []

export { render, staticRenderFns }