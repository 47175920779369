<template>

    <div>

        <!-- mensaje previo -->
        <div class="text-center">
            <v-dialog
                v-model="dialog"
                width="600">
                <template v-slot:activator="{ on, attrs }">

                    <!-- pendiente -->
                    <img v-if="pending"
                        class="btn-icon"
                        src="/static/img/btn/pdf_gris.png"
                        title="Próxima publicación" />

                    <!-- hay documento -->
                    <img v-else-if="value"
                        v-bind="attrs"
                        v-on="on"
                        class="btn-icon"
                        src="/static/img/btn/pdf.png"
                        title="Documento navegable optimizado" />

                </template>

                <v-card>
                    <v-card-title class="justify-center text-h5 grey lighten-2">
                        <img
                            :src="frontend_path + 'img/LogoCabildo_BN.png'"
                            width="55" />
                        <v-spacer></v-spacer>
                        <v-btn x-small plain @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text>
                        Advertimos que el historial de documentos recopilados que se alojan en esta página, en los casos en que no tienen diligencia manual o firma electrónica,
                        podrían presentar alguna discrepancia respecto a la versión custodiada por la Secretaría del Pleno del Cabildo de Gran Canaria,
                        órgano de referencia a estos efectos.<br/>
                        Para poder navegar por el documento PDF debe usar Mozilla Firefox como navegador web (descarga <a target="_blank" href="https://www.mozilla.org/es-ES/firefox/new/">aquí</a>).
                        Si ya ha abierto el documento con otro navegador, puede copiar la URL y abrirla en Mozilla Firefox.
                        <br/>Si lo prefiere, puede acceder a la página de descarga completa del documento a través del icono que existe en esta misma web
                        (<img src="/static/img/btn/descarga_sinfirma.png" style="width:20px;vertical-align:bottom" /> o <img src="/static/img/btn/descarga_confirma.png" style="width:20px;vertical-align:bottom" />)
                        y tras descomprimirlo en su ordenador, abrirlo con cualquier lector de PDF.
                        <br/>Consulte <a href="https://www.idegrancanaria.es/noticias/2022/02/ayuda-para-consultar-los-documentos-pdf-navegables" target="_blank">aquí</a> la ayuda.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="openPDF">
                            Abrir el documento PDF navegable optimizado
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>

    </div>
    
</template>

<script>
import { trackEvent } from '../modules/common'
export default {
    name: 'BrowsableDocument',
    props: {
        data_folder_path: String,
        value: String,
        pending: Boolean,
        icon_color: String
    },
    data: () => ({
        frontend_path: process.env.VUE_APP_FRONTEND_PATH,
        dialog: false
    }),
    methods: {
        trackEvent: trackEvent,
        openDialog: function() {
            this.dialog = true
        },
        openPDF: function() {
            this.trackEvent('Abrir documento navegable optimizado', this.value)
            this.dialog = false
            window.open(this.data_folder_path + this.value, '_blank');
        }
    }
}
</script>

<style scoped>
.v-dialog > .v-card > .v-card__text {
    color: #000;
    text-align: justify;
}
</style>
