import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '@/components/PNENP'
import basedownload from '@/components/BaseDownload'
import history_enp from '@/components/PNENPENPHistory'
import detailhistory_enp from '@/components/PNENPENPDetailHistory'
import history_rn from '@/components/PNENPRNHistory'
import detailhistory_rn from '@/components/PNENPRNDetailHistory'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/',
      name: 'home',
      component: home,
      props: {
        data_url: process.env.VUE_APP_PNENP_DATA_URL,
        data_folder_path_enp: process.env.VUE_APP_PNENP_ENP_DATA_FOLDER_PATH,
        data_folder_path_rn: process.env.VUE_APP_PNENP_RN_DATA_FOLDER_PATH,
        app_font_color: process.env.VUE_APP_PNENP_FONT_COLOR,
        app_title_bgcolor: process.env.VUE_APP_PNENP_TITLE_BGCOLOR,
        app_title_shadow: process.env.VUE_APP_PNENP_TITLE_SHADOW
      }
    },
    /*
     * ENP
     */
    {
      path: '/?ucode_enp=:code',
      name: 'Download_unsigned_enp',
      component: basedownload,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    },
    {
      path: '/?scode_enp=:code',
      name: 'Download_signed_enp',
      component: basedownload,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    },
    {
      path: '/?expcode_enp=:code',
      name: 'Download_admexp_enp',
      component: basedownload,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    },
    {
      // historial de trámites
      path: '/?ref_enp=:reference',
      name: 'History_enp',
      component: history_enp,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    },
    {
      // detalles de plan
      path: '/detail_enp',
      name: 'Detail_enp',
      component: detailhistory_enp,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    },
    /*
     * RN
     */
    {
      path: '/?ucode_rn=:code',
      name: 'Download_unsigned_rn',
      component: basedownload,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    },
    {
      path: '/?scode_rn=:code',
      name: 'Download_signed_rn',
      component: basedownload,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    },
    {
      path: '/?expcode_rn=:code',
      name: 'Download_admexp_rn',
      component: basedownload,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    },
    {
      // historial de trámites
      path: '/?ref_rn=:reference',
      name: 'History_rn',
      component: history_rn,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    },
    {
      // detalles de plan
      path: '/detail_rn',
      name: 'Detail_rn',
      component: detailhistory_rn,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    }
  ]
})

export default router
