<template>
    <div>
        <span v-for="(bulletin, index) in value" :key="bulletin.order">
            <a v-if="bulletin.url"
                :href="data_folder_path + bulletin.url"
                title="Publicación en boletín"
                target="_blank">
                {{ bulletin.text }}
            </a>
            <span v-else>{{ bulletin.text }}</span>
            <br v-if="index != Object.keys(value).length - 1"/>
        </span>
    </div>
</template>

<script>
export default {
    name: 'PublicInformations',
    props: {
        data_folder_path: String,
        value: Array
    }
}
</script>

<style scoped>
img {
    margin-left: 4px;
    margin-bottom: -4px;
}
</style>
