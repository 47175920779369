var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item.unsigned_documentation_pending)?_c('img',{staticClass:"btn-icon",attrs:{"src":"/static/img/btn/descarga_sinfirma_gris.png","title":"Próxima publicación"}}):(_vm.item.unsigned_documentation)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
        name: _vm.route_name,
        params: {
            base_url: _vm.data_url,
            data_url: _vm.data_url + 'unsigned_documentation/',
            data_folder_path: _vm.data_folder_path,
            doc_id: _vm.item.unsigned_documentation,
            code: _vm.item.code,
            caption_bgcolor: _vm.caption_bgcolor,
            title_caption: 'DESCARGAR DOCUMENTO SIN FIRMA ELECTRÓNICA',
            show_download_dialog: true
        }
    },"custom":""},nativeOn:{"click":function($event){_vm.trackEvent('Abrir página descarga (sin firma)', _vm.item.code)}}},[_c('img',{staticClass:"btn-icon",attrs:{"title":"Descargar sin firma electrónica, ficheros desde índice","src":"/static/img/btn/descarga_sinfirma.png"}})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }