<template>

    <v-card
        :style="'--caption-bgcolor:' + app_title_bgcolor">

        <v-tabs
            background-color="transparent"
            grow
            active-class="tab-active"
            v-model="tab"
            @change="changeTab"
        >
        
            <!-- Planes/normas de ENP -->
            <v-tab>Planes/normas de ENP</v-tab>
            <v-tab-item>
                <PNENPENP
                    :data_url="data_url + 'enp/'"
                    :data_folder_path="data_folder_path_enp"
                    :app_title_bgcolor="app_title_bgcolor"
                    :app_font_color="app_font_color"
                ></PNENPENP>
            </v-tab-item>

            <!-- Red Natura 2000 -->
            <v-tab>Red Natura 2000</v-tab>
            <v-tab-item>
                <PNENPRN
                    :data_url="data_url + 'rn/'"
                    :data_folder_path="data_folder_path_rn"
                    :app_title_bgcolor="app_title_bgcolor"
                    :app_font_color="app_font_color"
                ></PNENPRN>
            </v-tab-item>

        </v-tabs>

    </v-card>

</template>

<script>
    import { eventBus } from '../main'
    import PNENPENP from './PNENPENP'
    import PNENPRN from './PNENPRN'
    import {
        customSearch,
        getProcedure, getData, getItems, apiCall,
        trackEvent
    } from '../modules/common'

    export default {
        components: {
            PNENPENP,
            PNENPRN
        },
        name: 'PNENP',
        props: {
            data_url: String,
            data_folder_path_enp: String,
            data_folder_path_rn: String,
            app_title_bgcolor: String,
            app_font_color: String
        },
        data: () => ({
            page_title: 'PLANES Y NORMAS DE ESPACIOS NATURALES PROTEGIDOS. TRANSPARENCIA.',
            tab: 1,
        }),
        created: function() {
            eventBus.$emit('app_name_change', 'pnenp')
            eventBus.$on('search_change', (data) => {
                this.search = data
            })
            eventBus.$emit('page_title_change', this.page_title)
            eventBus.$emit('page_title_color_change', this.app_font_color)
            eventBus.$emit('page_title_shadow_change', this.app_title_shadow)
            eventBus.$emit('app_bar_color_change', this.app_title_bgcolor)
        },
        mounted: function() {
            let init = true
            try {
                // tab = 1: ENP
                // tab = 2: RN
                const suffix = this.tab == 1 ? '_enp' : '_rn'
                const subpath = suffix.replace('_', '') + '/'
                const data_folder_path = this.tab == 1 ? this.data_folder_path_enp : this.data_folder_path_rn
                // require history, signed, unsigned or adm_exp page?
                const ref = this.$route.query.ref_enp || this.$route.query.ref_rn
                const signed_code = this.$route.query.scode_enp || this.$route.query.scode_rn
                const unsigned_code = this.$route.query.ucode_enp || this.$route.query.ucode_rn
                const exp_code = this.$route.query.expcode_enp || this.$route.query.expcode_rn
                if (typeof ref !== 'undefined') {
                    this.base_url = this.data_url + subpath
                    init = false
                    this.$router.push({ name: 'home' }) // to return home with browser back
                    trackEvent.bind(this)('Historial de trámites', ref)
                    this.$router.push({
                        name: 'History' + suffix,
                        params: {
                            reference: ref,
                            page_title: this.page_title,
                            data_url: this.base_url,
                            data_folder_path: data_folder_path,
                            caption_bgcolor: this.app_title_bgcolor
                        }
                    })
                } else if (typeof unsigned_code !== 'undefined') {
                    this.base_url = this.data_url + subpath
                    this.getProcedure(unsigned_code, (proc) => {
                        if (proc.unsigned_documentation) {
                            init = false
                            trackEvent.bind(this)('Abrir página descarga (sin firma)', unsigned_code)
                            this.$router.push({
                                name: 'Download_unsigned' + suffix,
                                params: {
                                    base_url: this.base_url,
                                    data_url: this.base_url + 'unsigned_documentation/',
                                    data_folder_path: data_folder_path,
                                    code: proc.code,
                                    caption_bgcolor: this.caption_bgcolor,
                                    title_caption: 'DESCARGAR DOCUMENTO SIN FIRMA ELECTRÓNICA',
                                    show_download_dialog: true
                                }
                            })
                        }
                    })
                } else if (typeof signed_code !== 'undefined') {
                    this.base_url = this.data_url + subpath
                    this.getProcedure(signed_code, (proc) => {
                        if (proc.signed_documentation) {
                            init = false
                            trackEvent.bind(this)('Abrir página descarga (con firma)', signed_code)
                            this.$router.push({
                                name: 'Download_signed' + suffix,
                                params: {
                                    base_url: this.base_url,
                                    data_url: this.base_url + 'signed_documentation/',
                                    data_folder_path: data_folder_path,
                                    code: proc.code,
                                    caption_bgcolor: this.caption_bgcolor,
                                    title_caption: 'DESCARGAR DOCUMENTO DILIGENCIADO CON FIRMA ELECTRÓNICA',
                                    show_download_dialog: true
                                }
                            })
                        }
                    })
                } else if (typeof exp_code !== 'undefined') {
                    this.base_url = this.data_url + subpath
                    this.getProcedure(exp_code, (proc) => {
                        if (proc.administrative_exp_documentation) {
                            init = false
                            this.$router.push({
                                name: 'Download_admexp' + suffix,
                                params: {
                                    base_url: this.base_url,
                                    data_url: this.base_url + 'administrative_exp_documentation/',
                                    data_folder_path: data_folder_path,
                                    code: proc.code,
                                    caption_bgcolor: this.caption_bgcolor,
                                    title_caption: 'DESCARGAR EXPEDIENTE ADMINISTRATIVO',
                                    show_download_dialog: true,
                                    is_expedient: true,
                                }
                            })
                        }
                    })
                }
                // default: load main page
                if (init) {
                    document.title = this.page_title
                    this.tab = this.$store.state.pnenp_history.tab
                }
            } catch (e) {
                console.log(e)
            }
        },
        methods: {
            changeTab: function(tab) {
                this.$store.commit('pnenp_history/setTab', tab)
            },
            customSearch: customSearch,
            getData: getData,
            getItems: getItems,
            apiCall: apiCall,
            getProcedure: getProcedure
        }
    }
</script>

<style scoped>
:root {
  --font-caption-color: rgba(240, 240, 240, 0.87);
}

/* v-card */
.card-title {
    /* font-family: Bebas Neue; */
    background-color: var(--caption-bgcolor);
    color: var(--font-caption-color);
    text-shadow: 2px 2px #000;
    text-align: center;
    font-size: 1.5rem;
    height: 4rem;
    line-height: 1em;
    padding-top: 0.8em;
}

/* v-tab */
.tab-active {
    /* background-color: rgb(57,43,88); */
    /* background-color: #1e6aa8; */
    background-color: rgb(71, 114, 157);
    color: #eee !important;
    font-weight: bold;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
    background-color: #aaa;
}
</style>