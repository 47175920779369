<template>
    <div>
        <span v-for="(item, index) in value" :key="item.order">
            <!-- <router-link
                v-if="item.path && item.path.indexOf('doc_ref=') != -1"
                :to="{
                    name: 'Document',
                    params: {
                        base_url: data_url,
                        data_url: data_url + 'documentation/',
                        data_folder_path: data_folder_path,
                        code: item.reference,
                        caption_bgcolor: caption_bgcolor,
                        title_caption: 'DESCARGAR DOCUMENTO'
                    }
                }"
                title="router-link"
            >
                {{ item.name }}
            </router-link> -->
            <!-- item.path ha de ser una ruta relativa del tipo /?doc_ref=<reference> -->
            <router-link
                v-if="item.path && item.path.indexOf('doc_ref=') != -1"
                :to="item.path"
            >
                {{ item.name }}
            </router-link>
            <a v-else-if="item.path"
                :href="item.path"
                :title="item.name"
                target="_blank"
            >
                {{ item.name }}
            </a>
            <span v-else>{{ item.name }}</span>
            <br v-if="index != Object.keys(value).length - 1"/>
        </span>
    </div>
</template>

<script>
export default {
    name: 'Documents',
    props: {
        data_folder_path: String,
        value: Array,
        base_url: String,
        data_url: String,
        caption_bgcolor: String
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

img {
    margin-left: 4px;
    margin-bottom: -4px;
}
</style>
