<template>

    <div>

        <div class="title-history" :style="'--subtitle-bgcolor: ' + subtitle_bgcolor">{{ history_title }}</div>

        <!--
            Historial de trámites
        -->
        <v-data-table
            id="history-table"
            :headers="history_headers"
            hide-default-header
            :items="history_items"
            :items-per-page="-1"
            hide-default-footer
            :search="search"
            :custom-filter="customSearch"
            :loading="loading"
            sort-by="order"
            :sort-desc="false"
            dense
            item-class="data-item"
            must-sort
            :header-props="{sortIcon: 'mdi-arrow-up-thick'}"
        >
            <template v-slot:header="{ props }">
                <thead class="v-data-table-header">
                    <tr>
                        <th v-for="(item, index) in props.headers"
                            :key="item.id" :colspan="index == 0 ? 3 : 1"
                            role="columnheader" scope="col" :aria-label="item.text" class="text-start">
                            <span>{{ item.text }}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template #item="{ item }">
                <!-- fila separadora de grupo -->
                <tr v-if="item.first_item && !item.fist_row" class="separator">
                    <td colspan="4" class="separator"></td>
                </tr>
                <tr>
                    <td v-if="item.first_item" class="group-name" :title="item.processing_status">{{ item.processing_status }}</td>
                    <td v-else></td>
                    <td :title="item.processing_status_detail">{{ item.processing_status_detail }}</td>
                    <td class="procedure-datetext" :title="item.procedure_datetext">{{ item.procedure_datetext }}</td>
                    <td>
                        <Documents
                            :data_folder_path="dataFolderPath"
                            :value="item.documents"
                            :base_url="data_url"
                            :data_url="data_url"
                            :caption_bgcolor="caption_bgcolor"
                        >
                        </Documents>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <!--
            Plano de planta general
        -->
        <v-data-table
            id="blueprint-table"
            :headers="isMobile ? blueprint_headers_mobile : blueprint_headers"
            :hide-default-header="isMobile"
            :items="blueprint_items"
            :items-per-page="-1"
            hide-default-footer
            dense
            item-class="data-item"
        >
            <template #item.blueprint_url="{ item }">
                <a
                    v-if="item.blueprint_url"
                    :href="item.blueprint_url"
                    target="_blank" title="Clic para abrir en nueva ventana"
                    class="plan"
                >
                    <img id="img-plano-general" :src="item.blueprint_thumbnail_url" />
                </a>
                <div v-else class="unavailable-plan plan">Plano no disponible</div>
            </template>

        </v-data-table>

    </div>

</template>

<script>
    import { eventBus } from '../main'
    import {
        customSearch,
        getMainHistoryData,
        apiCall, getItems
    } from '../modules/common'
    import Documents from './BaseDocuments'

    export default {
        components: {
            Documents,
        },
        name: 'PIIHistory',
        props: {
            reference: String,
            page_title: String,
            data_url: String,
            data_folder_path: String,
            caption_bgcolor: String
        },
        data: () => ({
            options: {},
            history_title: '',
            history_items: [],
            history_total_items: 0,
            detail_title: 'Alteraciones del instrumento',
            detail_items: [],
            detail_total_items: 0,
            loading: true,
            search: '',
            history_headers: [
                {
                    // fase + trámite + fecha-texto
                    text: 'Procedimiento de aprobación',
                    value: 'processing_status',
                    sortable: false
                },
                {
                    text: 'Documentos',
                    value: 'documents',
                    sortable: false,
                    align: 'center',
                    width: 400
                },
            ],
            blueprint_headers: [
                {
                    text: 'Plano',
                    value: 'blueprint_url',
                    sortable: false
                }
            ],
            blueprint_headers_mobile: [
                {
                    value: 'blueprint_url',
                    sortable: false
                }
            ],
            blueprint_items: [],
            subtitle_bgcolor: '#d9d9d9'
        }),
        created: function() {
            eventBus.$on('search_change', (data) => {
                this.search = data;
            })
        },
        updated: function() {
            // history_title
            if (this.history_items.length > 0) {
                this.history_title = this.reference + '. ' + this.history_items[0].denomination
                document.title = this.history_title
            }
        },
        mounted: function() {
            if (typeof this.reference !== 'undefined') {
                this.$store.commit('pii_history/setReference', this.reference)
                this.$store.commit('pii_history/setPageTitle', this.page_title)
                this.$store.commit('pii_history/setDataUrl', this.data_url)
                this.$store.commit('pii_history/setDataFolderPath', this.data_folder_path)
                this.$store.commit('pii_history/setCaptionBgColor', this.caption_bgcolor)
            }
            this.getMainHistoryData(this.dataUrl, this.ref)
        },
        computed: {
            dataUrl: function() {
                return this.data_url || this.$store.state.pii_history.data_url
            },
            dataFolderPath: function() {
                return this.data_folder_path || this.$store.state.pii_history.data_folder_path
            },
            ref: function() {
                return this.reference || this.$store.state.pii_history.reference
            },
            captionBgColor: function() {
                return this.caption_bgcolor || this.$store.state.pii_history.caption_bgcolor
            },
            isMobile: function() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return true
                    case 'sm': return true
                    case 'md': return false
                    case 'lg': return false
                    case 'xl': return false
                    default: return false
                }
            },
        },
        methods: {
            customSearch: customSearch,
            getMainHistoryData: getMainHistoryData,
            apiCall: apiCall,
            getItems: getItems
        },
        watch: {
            history_items() {
                this.blueprint_items.push(
                    {
                        blueprint_url: this.history_items[0].blueprint_url,
                        blueprint_thumbnail_url: this.history_items[0].blueprint_thumbnail_url
                    }
                )
                // sólo el texto de la fase del primer trámite de cada fase
                this.history_items.forEach((ele, idx, arr) => {
                    if (idx == 0) {
                        ele.fist_row = true
                        ele.first_item = true
                    } else if (arr[idx - 1].processing_status == ele.processing_status) {
                        ele.first_item = false
                    } else {
                        ele.first_item = true
                    }
                })
            }
        },
    }
</script>

<style scoped>
.title-history {
    background-color: var(--subtitle-bgcolor);
    font-size: 0.875rem;
    line-height: 1.4rem;
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-top: 2rem;
}

.title-details {
    color: #000;
    /* simulamos negrita */
    text-shadow: 0.01rem 0.01rem #000;
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    min-height: 1.2rem;
    line-height: 0.9em;
    margin-top: 3rem;
    padding-left: 16px;
}

>>> td:has(> *.plan) {
    border: 1px solid black !important;
}
.unavailable-plan {
    width: 100%;
    height: 400px;
    line-height: 400px;
    text-align: center;
    font-size: larger;
}
.group-name {
    font-family: 'Century Gothic Paneuropean', sans-serif !important;
    font-weight: bold;
}

.procedure-datetext {
    text-align: center;
    width: 200px;
}

/* th v-data-table*/
>>> .v-data-table__wrapper > table > thead > tr > th {
    background-color: #1E6AA8 !important;
    color: #fff !important;
}

>>> .v-data-table tbody > tr {
    height: 55px !important;
}

>>> .v-data-table tbody > tr.separator {
    background-color: black;
    height: 2px !important;
}

>>> .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td.separator,
>>>.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td.separator,
>>> .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td.separator {
    height: 2px !important;
}

/* @media only screen and (max-width: 600px) { */
@media (pointer:none), (pointer:coarse) {
    >>>.theme--light.v-btn {
        color: #fff !important;
    }
    #history-table {
        width: 100%;
    }
    #blueprint-table {
        width: 100%;
        margin-top: 20px;
    }
    #img-plano-general {
        width: 100%;
    }
}
/* @media only screen and (min-width: 601px) { */
@media (pointer:fine) {
    #history-table {
        position: absolute;
        width: 65%;
    }
    #blueprint-table {
        position: absolute;
        right: 0;
        width: 35%;
    }
    #img-plano-general {
        height: 500px;
    }
}

</style>