import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/components/Index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index
    }
  ]
})

export default router
