import Vue from 'vue'
import VueRouter from 'vue-router'
import Route1 from '@/components/Landscape'
import Route2 from '@/components/BaseDownload'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Route1,
      props: {
        data_url: process.env.VUE_APP_LANDSCAPE_DATA_URL,
        data_folder_path: process.env.VUE_APP_LANDSCAPE_DATA_FOLDER_PATH,
      }
    },
    {
      path: '/documentation',
      name: 'LandscapeDownload',
      component: Route2,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    }
  ]
})

export default router
