<template>

    <img v-if="item.administrative_exp_documentation_pending"
        class="btn-icon"
        src="/static/img/btn/expediente_gris.png"
        title="Próxima publicación" />

    <router-link
        v-else-if="item.administrative_exp_documentation"
        :to="{
            name: route_name,
            params: {
                base_url: data_url,
                data_url: data_url + 'administrative_exp_documentation/',
                data_folder_path: data_folder_path,
                doc_id: item.administrative_exp_documentation,
                code: item.code,
                caption_bgcolor: caption_bgcolor,
                is_expedient: true,
                title_caption: 'DESCARGAR EXPEDIENTE ADMINISTRATIVO'
            }
        }"
        custom>
        <img
            title="Expediente administrativo"
            class="btn-icon"
            src="/static/img/btn/expediente.png" />
    </router-link>
</template>

<script>
export default {
    name: 'AdministrativeExpedientDownload',
    props: {
        data_url: String,
        data_folder_path: String,
        item: Object,
        caption_bgcolor: String,
        route_name: {
            type: String,
            default: 'Download_admexp'
        }
    }
}
</script>
