import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '@/components/PII'
import history from '@/components/PIIHistory'
import basedownload from '@/components/BaseDownloadPII'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      // relación de planes y su última modificación de trámite
      path: '/',
      name: 'home',
      component: home,
      props: {
        data_url: process.env.VUE_APP_PII_DATA_URL,
        data_folder_path: process.env.VUE_APP_PII_DATA_FOLDER_PATH,
        app_font_color: process.env.VUE_APP_PII_FONT_COLOR,
        app_title_bgcolor: process.env.VUE_APP_PII_TITLE_BGCOLOR,
        app_title_shadow: process.env.VUE_APP_PII_TITLE_SHADOW
      }
    },
    {
      // historial de trámites
      // path: '/history',
      path: '/?ref=:reference',
      name: 'History',
      component: history,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    },
    {
      // path: '/documentation',
      path: '/?doc_ref=:code',
      name: 'Document',
      component: basedownload,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    }
  ]
})

export default router
