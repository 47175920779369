<template>

    <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :custom-filter="customSearch"
        :loading="loading"
        sort-by="last_procedure_date"
        :sort-desc="true"
        :items-per-page="-1"
        must-sort
        :header-props="{sortIcon: 'mdi-arrow-up-thick'}"
    >

        <!-- imagen -->
        <template #item.image_path="{ item }">
            <img v-if="item.image_path" :src="data_folder_path + item.image_path" class="image-enp"/>
        </template>

        <!-- historial -->
        <template v-slot:item.id="{ item }">
            <History
                :route_name="'History_enp'"
                :reference="item.reference"
                :page_title="page_title"
                :data_url="data_url"
                :data_folder_path="data_folder_path"
                :caption_bgcolor="app_title_bgcolor">
            </History>
        </template>

    </v-data-table>

</template>

<script>
    import { eventBus } from '../main'
    import {
        customSearch,
        getProcedure, getData, getItems, apiCall,
        // trackEvent
    } from '../modules/common'
    import History from './BaseHistory'

    export default {
        components: {
            History
        },
        name: 'PNENPENP',
        props: {
            data_url: String,
            data_folder_path: String,
            app_title_bgcolor: String,
            app_font_color: String
        },
        data: () => ({
            // page_title: 'Planes y normas de ENP',
            page_title: 'PLANES Y NORMAS DE ESPACIOS NATURALES PROTEGIDOS. TRANSPARENCIA.',
            options: {},
            items: [],
            totalItems: 0,
            loading: true,
            search: '',
            page: 1,
            headers: [
                {
                    text: 'Referencia',
                    value: 'reference',
                    sortable: true,
                    align: 'left',
                    width: 125
                },
                {
                    text: 'Instrumento',
                    value: 'instrument',
                    sortable: true,
                    width: 300
                },
                {
                    text: 'Denominación',
                    value: 'denomination',
                    sortable: true
                },
                {
                    text: 'Imagen',
                    value: 'image_path',
                    sortable: false,
                    align: 'center'
                },
                {
                    text: 'Historial de trámites',
                    value: 'id',
                    sortable: false,
                    align: 'center'
                },
                {
                    text: 'Última tramitación',
                    value: 'last_procedure_date',
                    sortable: true,
                    align: 'center'
                },
            ]
        }),
        created: function() {
            eventBus.$emit('app_name_change', 'pnenp')
            eventBus.$on('search_change', (data) => {
                this.search = data
            })
            eventBus.$emit('page_title_change', this.page_title)
            eventBus.$emit('page_title_color_change', this.app_font_color)
            eventBus.$emit('page_title_shadow_change', this.app_title_shadow)
            eventBus.$emit('app_bar_color_change', this.app_title_bgcolor)
        },
        mounted: function() {
            // let init = true
            // try {
            //     // require history, signed, unsigned or adm_exp page?
            //     const ref = this.$route.query.ref
            //     const signed_code = this.$route.query.scode
            //     const unsigned_code = this.$route.query.ucode
            //     const exp_code = this.$route.query.expcode
            //     if (typeof ref !== 'undefined') {
            //         init = false
            //         this.$router.push({ name: 'home' }) // to return home with browser back
            //         trackEvent.bind(this)('Historial de trámites', ref)
            //         this.$router.push({
            //             name: 'History',
            //             params: {
            //                 reference: ref,
            //                 page_title: this.page_title,
            //                 data_url: this.data_url,
            //                 data_folder_path: this.data_folder_path
            //             }
            //         })
            //     } else if (typeof unsigned_code !== 'undefined') {
            //         this.base_url = this.data_url
            //         this.getProcedure(unsigned_code, (proc) => {
            //             if (proc.unsigned_documentation) {
            //                 init = false
            //                 trackEvent.bind(this)('Abrir página descarga (sin firma)', unsigned_code)
            //                 this.$router.push({
            //                     name: 'Download_unsigned',
            //                     params: {
            //                         base_url: this.data_url,
            //                         data_url: this.data_url + 'unsigned_documentation/',
            //                         data_folder_path: this.data_folder_path,
            //                         code: proc.code,
            //                         // doc_id: proc.unsigned_documentation,
            //                         caption_bgcolor: this.caption_bgcolor,
            //                         title_caption: 'DESCARGAR DOCUMENTO SIN FIRMA ELECTRÓNICA',
            //                         show_download_dialog: true
            //                     }
            //                 })
            //             }
            //         })
            //     } else if (typeof signed_code !== 'undefined') {
            //         this.base_url = this.data_url
            //         this.getProcedure(signed_code, (proc) => {
            //             if (proc.signed_documentation) {
            //                 init = false
            //                 trackEvent.bind(this)('Abrir página descarga (con firma)', signed_code)
            //                 this.$router.push({
            //                     name: 'Download_signed',
            //                     params: {
            //                         base_url: this.data_url,
            //                         data_url: this.data_url + 'signed_documentation/',
            //                         data_folder_path: this.data_folder_path,
            //                         code: proc.code,
            //                         // doc_id: proc.signed_documentation,
            //                         caption_bgcolor: this.caption_bgcolor,
            //                         title_caption: 'DESCARGAR DOCUMENTO DILIGENCIADO CON FIRMA ELECTRÓNICA',
            //                         show_download_dialog: true
            //                     }
            //                 })
            //             }
            //         })
            //     } else if (typeof exp_code !== 'undefined') {
            //         this.base_url = this.data_url
            //         this.getProcedure(exp_code, (proc) => {
            //             if (proc.administrative_exp_documentation) {
            //                 init = false
            //                 this.$router.push({
            //                     name: 'Download_admexp',
            //                     params: {
            //                         base_url: this.data_url,
            //                         data_url: this.data_url + 'administrative_exp_documentation/',
            //                         data_folder_path: this.data_folder_path,
            //                         code: proc.code,
            //                         // doc_id: proc.administrative_exp_documentation,
            //                         caption_bgcolor: this.caption_bgcolor,
            //                         title_caption: 'DESCARGAR EXPEDIENTE ADMINISTRATIVO',
            //                         show_download_dialog: true,
            //                         is_expedient: true,
            //                     }
            //                 })
            //             }
            //         })
            //     }
            //     // default: load main page
            //     if (init) {
            //         document.title = this.page_title
            //         this.getData(this.data_url)
            //     }
            // } catch (e) {
            //     console.log(e)
            // }
            document.title = this.page_title
            this.getData(this.data_url)
        },
        methods: {
            customSearch: customSearch,
            getData: getData,
            getItems: getItems,
            apiCall: apiCall,
            getProcedure: getProcedure
        }
    }
</script>

<style scoped>
.image-enp {
    height: 90px;
    width: 200px;
}
/* th v-data-table */
>>> .v-data-table__wrapper>table>thead>tr>th {
    background-color: #d9d9d9 !important;
    color: #000 !important;
}
</style>