<template>
    <router-link
        :to="{
            name: route_name,
            params: {
                data_url: data_url,
                reference: reference,
                data_folder_path: data_folder_path,
                caption_bgcolor: caption_bgcolor,
                page_title: page_title,
                history_title: history_title,
                detail_title: detail_title,
            }
        }"
        @click.native="trackEvent('Historial de trámites', ga4Action)"
        custom style="text-decoration:none"
    >
        <img class="btn-icon" src="/static/img/btn/historial.png" title="Historial de trámites" />
    </router-link>
</template>

<script>
import {
    trackEvent
} from '../modules/common'
export default {
    name: 'History',
    props: {
        ga_action: String, // google analytics
        data_url: String,
        data_folder_path: String,
        reference: String,
        instrument: String,
        denomination: String,
        page_title: String,
        history_title: String,
        detail_title: String,
        caption_bgcolor: String,
        route_name: {
            type: String,
            default: 'History'
        }
    },
    data: () => ({
        frontend_path: process.env.VUE_APP_FRONTEND_PATH
    }),
    methods:{
        trackEvent: trackEvent
    },
    computed: {
        ga4Action() {
            if (typeof this.ga_action !== 'undefined') {
                return this.ga_action
            } else {
                return this.reference
            }
        }
    }
}
</script>