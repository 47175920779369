// initial state
const state = () => ({
  reference: null,
  data_url: null,
  data_folder_path: null,
  page_title: null,
  history_title: null,
  detail_title: null,
  caption_bgcolor: null
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setReference (state, reference) {
    state.reference = reference
  },
  setDataUrl (state, url) {
    state.data_url = url
  },
  setDataFolderPath (state, path) {
    state.data_folder_path = path
  },
  setPageTitle (state, title) {
    state.page_title = title
  },
  setHistoryTitle (state, title) {
    state.history_title = title
  },
  setDetailTitle (state, title) {
    state.detail_title = title
  },
  setCaptionBgColor (state, color) {
    state.caption_bgcolor = color
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}