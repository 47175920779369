import { render, staticRenderFns } from "./PNENP.vue?vue&type=template&id=122d5b8e&scoped=true"
import script from "./PNENP.vue?vue&type=script&lang=js"
export * from "./PNENP.vue?vue&type=script&lang=js"
import style0 from "./PNENP.vue?vue&type=style&index=0&id=122d5b8e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "122d5b8e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VTab,VTabItem,VTabs})
