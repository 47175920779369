<template>
  <div>
    <h1>Página no encontrada</h1>
  </div>
</template>

<script>
export default {
  name: 'Index',
  props: {
    msg: String
  }
}
</script>