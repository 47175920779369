<template>

    <v-data-table
        :headers="headers"
        :items="uniqueItems"
        :page.sync="page"
        :search="search"
        :custom-filter="customSearch"
        :loading="loading"
        sort-by="resolution_date"
        :sort-desc="true"
        dense
        item-class="data-item"
        :caption="page_title"
        :style="'--caption-bgcolor:' + caption_bgcolor"
    >

        <!-- documentation download -->
        <template #item.documentation="{ value }">
            <router-link
                :to="{
                    name: 'LandscapeDownload',
                    params: {
                        data_url: data_url + 'documentation/',
                        data_folder_path: data_folder_path,
                        doc_id: value,
                        caption_bgcolor: caption_bgcolor
                    }
                }"
                custom
            >Descarga de documentación</router-link>
        </template>

        <!-- location -->
        <template #item.location_url="{ value }">
            <Location
                :value="value"
                :img_size="'30px'">
            </Location>
        </template>

    </v-data-table>

</template>

<script>
    import _ from 'lodash'
    import { eventBus } from '../main'
    import {
        customSearch,
        getData, getItems, apiCall
    } from '../modules/common'
    import Location from './BaseLocation'

    export default {
        name: 'Landscape',
        props: {
            data_url: String,
            data_folder_path: String,
        },
        components: {
            Location
        },
        data: function() {
            return {
                page_title: 'Paisaje: Proyectos Directores. Proyectos de Ejecución.',
                items: [],
                totalItems: 0,
                loading: true,
                search: '',
                page: 1,
                caption_bgcolor: '#da9027',
                headers: [
                    {
                        text: 'Tipo de actuación',
                        value: 'action_type',
                        sortable: false,
                        align: 'center'
                    },
                    {
                        text: 'Denominación',
                        value: 'denomination',
                        sortable: false
                    },
                    {
                        text: 'Tramitación',
                        value: 'processing_status',
                        sortable: false,
                        align: 'center'
                    },
                    {
                        text: 'Fecha de resolución',
                        value: 'resolution_date',
                        sortable: true,
                        align: 'center'
                    },
                    {
                        text: 'Municipio',
                        value: 'municipality',
                        sortable: false
                    },
                    {
                        text: 'Estado de ejecución',
                        value: 'implementation_status',
                        sortable: false,
                        align: 'center'
                    },
                    {
                        text: 'Descarga de archivos',
                        value: 'documentation',
                        sortable: false,
                        align: 'center'
                    },
                    {
                        text: 'Localización',
                        value: 'location_url',
                        sortable: false,
                        align: 'center'
                    }
                ],
            }
        },
        created: function() {
            eventBus.$on('search_change', (data) => {
                this.search = data;
            })
        },
        computed: {
            uniqueItems: function() {
                return _.uniqWith(this.items, _.isEqual)
            }
        },
        watch: {
            search: function() {
                this.getData()
            }
        },
        mounted: function() {
            document.title = this.page_title
            this.getData()
        },
        methods: {
            customSearch: customSearch,
            getData: getData,
            getItems: getItems,
            apiCall: apiCall
        }
    }
</script>

<style scoped>
.v-data-table::v-deep caption {
  background-color: var(--caption-bgcolor);
}
</style>