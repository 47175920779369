import Vue from 'vue'
import VueRouter from 'vue-router'
import Route1 from '@/components/DecPubSocInt'
import Route2 from '@/components/DecPubSocIntHistory'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: __dirname,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Route1,
      props: {
        data_url: process.env.VUE_APP_DECPUBSOCINT_DATA_URL,
        data_folder_path: process.env.VUE_APP_DECPUBSOCINT_DATA_FOLDER_PATH,
        app_font_color: process.env.VUE_APP_DECPUBSOCINT_FONT_COLOR,
        app_title_bgcolor: process.env.VUE_APP_DECPUBSOCINT_TITLE_BGCOLOR,
        app_title_shadow: process.env.VUE_APP_DECPUBSOCINT_TITLE_SHADOW
      }
    },
    {
      // historial de trámites
      // path: '/history',
      path: '/?ref=:reference',
      name: 'History',
      component: Route2,
      // when props is set to true, the route.params will be set as the component props.
      props: true
    },
  ]
})

export default router
